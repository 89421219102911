<template>
  <div>
    <mdb-row center>
      <mdb-avatar :width="40" center>
        <img :src="localPhoto" class="rounded-circle" />
      </mdb-avatar>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <div class="" style="" v-show="!slimCollapsed">
          <strong>{{ user.userName }}</strong>
          <mdb-icon color="success" icon="circle" class="ml-2" size="sm" />
          <br />
          <strong>{{ user.email }} </strong>
          <br />
          <mdb-btn @click="signOut()" flat size="sm"
            >{{ $t("login.signout") }}
          </mdb-btn>
        </div>
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import defaultImage from "@/assets/img/user-alt.svg";
import { mdbAvatar, mdbIcon, mdbBtn, mdbRow, mdbCol } from "mdbvue";
import * as userClient from "@/client/UserClient.js";
import * as msClient from "@/client/MSClient.js";

export default {
  name: "LoginMsal",
  components: {
    mdbAvatar,
    mdbIcon,
    mdbBtn,
    mdbRow,
    mdbCol,
  },
  props: { content: Object, slimCollapsed: Boolean },
  data() {
    return {
      localPhoto: defaultImage,
      user: {
        accessToken: null,
        userName: null,
        email: null,
        userPhoto: null,
      },
    };
  },
  async created() {},
  mounted() {
    if (this.$store.getters.isAuthenticated) {
      this.user = this.$store.getters.getUser;
      this.$store.commit("updateUser", this.user);
      const accounts = msClient.getAllAccounts();
      if (accounts.length == 0) {
        console.log("No ms accounts found from msInstance user is not updated");
        return;
      }
      this.fillUser(accounts[0]);
      const user = accounts[0];

      userClient.createUser(
        user.username,
        user.name.split(" ")[0],
        user.name.split(" ")[1]
      );
    }
  },
  methods: {
    clearUser() {
      this.user.email = this.user.userName = this.user.accessToken = "";
      this.user.userPhoto = defaultImage;
      this.$store.commit("updateUser", this.user);
    },
    fillUser(account) {
      this.user.userName = account.name;
      this.user.email = account.username;
      if (!this.isBase64Image(this.user.userPhoto)) {
        //console.log("getting user image");
        this.setUserPhoto();
      } else {
        //console.log("slicing photo");
        this.localPhoto = this.user.userPhoto.slice();
      }
      //this.$store.commit("updateUser", this.user);
    },
    isBase64Image(str) {
      if (!str) {
        return false;
      }
      try {
        return str.includes("data:image");
      } catch (err) {
        return false;
      }
    },
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    async setUserPhoto() {
      var user;
      if ((user = await userClient.getUser(this.user.email))) {
        if (user.image) {
          this.user.userPhoto = "data:image/jpeg;base64, " + user.image;
          this.localPhoto = this.user.userPhoto.slice();
          this.$store.commit("updateUser", this.user);
        }
      }

      /*
      await this.$http
        .get("https://graph.microsoft.com/v1.0/me/photo/$value", {
          headers: { Authorization: `Bearer ${this.user.accessToken}` },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.user.userPhoto =
            "data:image/jpeg;base64, " +
            this.arrayBufferToBase64(response.data);
          this.localPhoto = this.user.userPhoto.slice();
          this.$store.commit("updateUser", this.user);
        })
        .catch((error) => {
          return error;
        });
        */
    },
    async signOut() {
      await msClient.signOutMsUser();
      this.clearUser();
      // avoid tuple navigation error if already at Home:
      if (this.$route.path !== "/") {
        this.$router.push({
          name: "Home",
        });
      }
    },
  },
};
</script>

<style></style>
