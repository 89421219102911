<template>
  <mdb-container class="grey darken-2 mt-3 mb-2">
    <mdb-row>
      <img style="height: 70px" src="@/assets/img/booster_vaaka.png" />
    </mdb-row>
    <mdb-row class="black">
      <h2 class="white-text">{{ title }}</h2>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow } from "mdbvue";
export default {
  name: "HeaderBase",
  components: {
    mdbContainer,
    mdbRow,
  },
  props: {
    title: String,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
