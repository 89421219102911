import { getApi, postApi, putApi, deleteApi } from "@/client/AxiosClient.js";
import i18n from "@/lang/lang.js";
import store from "@/store/index.js";

export async function getEvents() {
  return await getApi({
    url: `${store.getters.getUser.email}/events`,
    errorMsg: i18n.t("event.load_not_success"),
  });
}

export async function getEvent(eventid) {
  return await getApi({
    url: `${store.getters.getUser.email}/events/${eventid}`,
    errorMsg: i18n.t("event.load_not_success"),
  });
}

export async function saveEvent(event) {
  if (event.id && event.id != "") {
    return await putApi({
      url: `${store.getters.getUser.email}/events/${event.id}`,
      body: event,
      message: i18n.t("event.update_not_success"),
    });
  } else {
    return await postApi({
      url: `${store.getters.getUser.email}/events`,
      body: event,
      errorMsg: i18n.t("event.create_not_success"),
    });
  }
}

export async function deleteEvent(eventid) {
  return await deleteApi({
    url: `${store.getters.getUser.email}/events/${eventid}`,
    body: i18n.t("event.delete_not_success"),
  });
}

export async function getEventUsers(eventid) {
  return await getApi({
    url: `${store.getters.getUser.email}/events/${eventid}/users`,
    errorMsg: i18n.t("event.user_load_not_success"),
  });
}

export async function addEventUsers(eventid, users) {
  return await putApi({
    url: `${store.getters.getUser.email}/events/${eventid}/users`,
    body: users,
    errorMsg: i18n.t("event.users_not_added_successfully"),
  });
}

export async function deleteEventUser(eventid, eventuserid) {
  return await deleteApi({
    url: `${store.getters.getUser.email}/events/${eventid}/users/${eventuserid}`,
    errorMsg: i18n.t("event.user_not_deleted_successfully"),
  });
}

export async function getEventLocations(eventid) {
  return await getApi({
    url: `${store.getters.getUser.email}/events/${eventid}/locations`,
    errorMsg: "",
  });
}

export async function addEventLocation(eventid, location) {
  return await putApi({
    url: `${store.getters.getUser.email}/events/${eventid}/locations`,
    body: location,
    errorMsg: i18n.t("event.location.location_not_added_successfully"),
  });
}

export async function deleteEventLocation(eventid, eventlocationid) {
  return await deleteApi({
    url: `${store.getters.getUser.email}/events/${eventid}/locations/${eventlocationid}`,
    errorMsg: i18n.t("event.location.location_not_deleted_successfully"),
  });
}

export async function checkIfLogicalIdIsUsed(logicalId) {
  const response = await getApi({
    url: `${store.getters.getUser.email}/events/logicalids/${logicalId}`,
    errorMsg: i18n.t("event.logicalid_not_checked_successfully"),
  });
  return response === "true" ? true : false;
}

export async function getEventContacts(eventid) {
  return await getApi({
    url: `${store.getters.getUser.email}/events/${eventid}/contacts`,
    errorMsg: "",
  });
}

export async function addEventContact(eventid, body) {
  return await putApi({
    url: `${store.getters.getUser.email}/events/${eventid}/contacts`,
    body,
    errorMsg: i18n.t("event.contact_not_added_successfully"),
  });
}

export async function deleteEventContact(eventid, eventcontactid) {
  return await deleteApi({
    url: `${store.getters.getUser.email}/events/${eventid}/contacts/${eventcontactid}`,
    errorMsg: i18n.t("event.contact_not_deleted_successfully"),
  });
}
