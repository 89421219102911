<template>
  <div class="home">
    <HomeHeader v-on:clicked="handleClick()" />
    <Events v-if="this.$store.getters.isAuthenticated" />
    <Welcome v-if="!this.$store.getters.isAuthenticated" />
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader.vue";
import Events from "@/components/events/Events.vue";
import Welcome from "@/components/Welcome.vue";

export default {
  name: "Home",
  components: {
    Events,
    HomeHeader,
    Welcome,
  },
  methods: {
    handleClick() {
      this.$router.push("/editevent");
    },
  },
};
</script>
