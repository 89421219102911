<template>
  <mdb-container class="grey lighten-5">
    <mdb-row class="justify-content-end">
      <mdb-col col="3">
        <mdb-input v-model="search" bg :label="$t('user.search')" />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-end">
      <mdb-btn outline="elegant" @click="addUsers()">{{
        $t("user.add_selected")
      }}</mdb-btn>
      <mdb-btn outline="elegant" @click="close()">{{
        $t("common.close_modal")
      }}</mdb-btn>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-datatable-2
          v-model="users"
          @selected="selectedUsers = $event"
          :searching="{ value: search }"
          hover
          multiselectable
          ref="datatable"
          selectColor="indigo lighten-3"
        />
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbDatatable2,
  mdbCol,
  mdbInput,
  mdbBtn,
} from "mdbvue";
import * as userClient from "@/client/UserClient.js";
export default {
  name: "UserSelector",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
    mdbDatatable2,
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      if ((this.localUsers = await userClient.getUsers())) {
        this.users = {
          columns: this.columns,
          rows: this.localUsers,
        };
      }
    },
    addUsers() {
      this.$emit("usersSelected", this.selectedUsers);
    },
    close() {
      this.$emit("closed");
    },
  },
  data() {
    return {
      selectedUsers: [],
      search: "",
      columns: [
        {
          label: this.$t("user.id"),
          field: "id",
          sort: true,
        },
        {
          label: this.$t("user.firstname"),
          field: "firstname",
          sort: true,
        },
        {
          label: this.$t("user.lastname"),
          field: "lastname",
          sort: true,
        },
        {
          label: this.$t("user.phonenumber"),
          field: "phonenumber",
          sort: true,
        },
      ],
      users: {
        rows: [],
        columns: this.localUsers,
      },
    };
  },
};
</script>

<style></style>
