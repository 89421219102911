<template>
  <div>
    <EventPhases v-on:selectedPhase="selectedPhase" />
    <KeepAlive>
      <component :is="activeComponent" />
    </KeepAlive>
  </div>
</template>

<script>
import EventPhases from "@/components/events/eventphases/EventPhases.vue";
import EventBasicInformation from "@/components/events/eventphases/EventBasicInformation.vue";
import EventNotes from "@/components/events/eventphases/EventNotes.vue";
import EventProductionPlan from "@/components/events/eventphases/EventProductionPlan.vue";
import EventRunPlan from "@/components/events/eventphases/EventRunPlan.vue";
import EventMaterials from "@/components/events/eventphases/EventMaterials.vue";
import EventPreview from "@/components/events/eventphases/EventPreview.vue";
export default {
  name: "EventPhaseControl",
  components: {
    EventPhases,
    EventBasicInformation,
    EventNotes,
    EventProductionPlan,
    EventRunPlan,
    EventMaterials,
    EventPreview,
  },
  created: function () {},
  mounted() {},
  data: () => ({
    activeComponent: "EventBasicInformation",
  }),
  methods: {
    selectedPhase(val) {
      this.activeComponent = val;
    },
  },
};
</script>

<style></style>
