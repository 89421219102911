<template>
  <mdb-container class="grey lighten-5">
    <mdb-row class="justify-content-end">
      <mdb-col col="3">
        <mdb-input v-model="search" bg :label="$t('location.search')" />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-end">
      <mdb-btn outline="elegant" @click="addLocations()">{{
        $t("location.add_selected")
      }}</mdb-btn>
      <mdb-btn outline="elegant" @click="showModal">{{
        $t("location.add_new")
      }}</mdb-btn>
      <mdb-btn outline="elegant" @click="close()">{{
        $t("common.close_modal")
      }}</mdb-btn>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-datatable-2
          v-model="locations"
          @selected="selectedLocations = $event"
          :searching="{ value: search }"
          hover
          multiselectable
          ref="datatable"
          selectColor="indigo lighten-3"
        />
      </mdb-col>
    </mdb-row>
    <EditLocationModal
      :location="{}"
      :show="showAddNewModal"
      :key="componentKey"
      :frameStyle="true"
      v-on:modal-ok="newLocationCreated"
    />
  </mdb-container>
</template>

<script>
import * as locationClient from "@/client/LocationClient.js";
import EditLocationModal from "@/components/locations/EditLocationModal.vue";
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbInput,
  mdbDatatable2,
} from "mdbvue";

export default {
  name: "LocationSelector",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbInput,
    mdbDatatable2,
    EditLocationModal,
  },
  methods: {
    async fetchLocations() {
      if ((this.localLocations = await locationClient.getLocations())) {
        this.locations = {
          columns: this.columns,
          rows: this.localLocations,
        };
      }
    },
    close() {
      this.$emit("closed");
    },
    addLocations() {
      this.$emit("locationsSelected", this.selectedLocations);
    },
    showModal() {
      this.componentKey += 1;
      this.showAddNewModal = true;
    },
    async newLocationCreated(location) {
      var payload;
      if ((payload = await locationClient.saveLocation(location))) {
        location.id = payload.id;
        this.localLocations.unshift(location);
        this.locations = {
          columns: this.columns,
          rows: this.localLocations,
        };
      }
    },
  },
  created: function () {
    this.fetchLocations();
  },
  data() {
    return {
      selectedLocations: [],
      showAddNewModal: false,
      componentKey: 0,
      search: "",
      columns: [
        {
          label: this.$t("location.name"),
          field: "name",
          sort: true,
        },
        {
          label: this.$t("location.streetAddress"),
          field: "streetAddress",
          sort: true,
        },
        {
          label: this.$t("location.postalcode"),
          field: "postalcode",
          sort: true,
        },
        {
          label: this.$t("location.city"),
          field: "city",
          sort: true,
        },
      ],
      locations: {
        rows: [],
        columns: this.localLocations,
      },
    };
  },
};
</script>

<style></style>
