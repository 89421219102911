import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: null,
    event: null,
    runplanResponsibles: [],
    runplanUsers: [],
    runplanLocations: [],
  },
  mutations: {
    updateUser(state, user) {
      state.user = user;
    },
    updateEvent(state, event) {
      state.event = event;
    },
    addRunplanResponsibles(state, responsibles) {
      console.log("STORING RESPONSIBLES: " + JSON.stringify(responsibles));
      state.runplanResponsibles = responsibles;
    },
    addRunplanUsers(state, users) {
      console.log("STORING USERS: " + JSON.stringify(users));
      state.runplanUsers = users;
    },
    addRunplanLocations(state, locations) {
      console.log("STORING LOCATIONS: " + JSON.stringify(locations));
      state.runplanLocations = locations;
    },
  },
  actions: {},
  getters: {
    getUser: (state) => {
      return state.user;
    },
    getEvent: (state) => {
      return state.event;
    },
    getRunplanResponsibles: (state) => {
      return state.runplanResponsibles;
    },
    getRunplanUsers: (state) => {
      return state.runplanUsers;
    },
    getRunplanLocations: (state) => {
      console.log(
        "GETTING LOCATIONS FROM STORE: " +
          JSON.stringify(state.runplanLocations)
      );
      return state.runplanLocations;
    },
    isAuthenticated: (state) => {
      return (
        state.user &&
        state.user.accessToken &&
        state.user.accessToken.length > 0
      );
    },
  },
});

export default store;
