<template>
  <mdb-container v-if="contact">
    <mdb-modal
      :show="showLocal"
      @close="modalClosed()"
      size="lg"
      removeBackdrop
    >
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100">{{
          $t("contact.information")
        }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="mx-3 grey-text">
        <mdb-row>
          <mdb-col>
            <SelectSingle
              :options="companies"
              :label="$t('contact.company_select_label')"
              :placeholder="$t('contact.company_select_placeholder')"
              :text="'name'"
              :value="'id'"
              v-on:selection="companySelected"
              :selectedValue="contact.companyId"
              v-if="companies"
            />
          </mdb-col>
        </mdb-row>
        <div>
          <mdb-row>
            <mdb-col col="6">
              <mdb-input
                v-model="contact.firstname"
                :label="$t('contact.firstname')"
                type="text"
                @blur="validateFirstname"
                :customValidation="customValidationOn"
                validFeedback=" "
                :isValid="firstnameIsValid"
                :invalidFeedback="this.$t('contact.give_a_firstname')"
              />
            </mdb-col>
            <mdb-col col="6">
              <mdb-input
                v-model="contact.lastname"
                :label="$t('contact.lastname')"
                type="text"
                @blur="validateLastname"
                :customValidation="customValidationOn"
                validFeedback=" "
                :isValid="lastnameIsValid"
                :invalidFeedback="this.$t('contact.give_a_lastname')"
              />
            </mdb-col>
          </mdb-row>
          <mdb-input v-model="contact.title" :label="$t('contact.title')" />
          <GooglePlacesAutoCompleteJS v-on:place-result="placeResult" />
          <mdb-row>
            <mdb-col col="6">
              <mdb-input v-model="contact.email" :label="$t('contact.email')" />
            </mdb-col>
            <mdb-col>
              <mdb-input
                v-model="contact.phonenumber"
                :label="$t('contact.phonenumber')"
              />
            </mdb-col>
          </mdb-row>
          <mdb-input
            v-model="contact.streetAddress"
            :label="$t('contact.streetAddress')"
          />
          <mdb-row>
            <mdb-col col="4">
              <mdb-input
                v-model="contact.postalcode"
                :label="$t('contact.postalcode')"
              />
            </mdb-col>
            <mdb-col col="8">
              <mdb-input v-model="contact.city" :label="$t('contact.city')" />
            </mdb-col>
          </mdb-row>
          <mdb-input
            :label="$t('contact.notes')"
            type="textarea"
            v-model="contact.notes"
          />
        </div>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn @click="savingContact()" outline="elegant">
          {{ $t("contact.save") }}
        </mdb-btn>
        <mdb-btn @click="modalClosed()" outline="elegant">
          {{ $t("contact.quit") }}
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbInput,
  mdbModalFooter,
  mdbRow,
  mdbCol,
} from "mdbvue";
import GooglePlacesAutoCompleteJS from "@/components/GooglePlacesAutoCompleteJS.vue";
import SelectSingle from "@/components/SelectSingle.vue";
import * as companyClient from "@/client/CompanyClient.js";
export default {
  name: "EditContactModal",
  components: {
    mdbContainer,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbInput,
    mdbModalFooter,
    mdbModalTitle,
    mdbRow,
    mdbCol,
    SelectSingle,
    GooglePlacesAutoCompleteJS,
  },
  props: {
    show: Boolean,
    contact: Object,
  },
  methods: {
    placeResult(val) {
      this.contactLocal.streetAddress = val.streetaddress;
      this.contactLocal.postalcode = val.postalcode;
      this.contactLocal.city = val.city;
    },
    companySelected(val) {
      this.contactLocal.companyId = val.id;
    },
    savingContact() {
      if (!this.lastnameIsValid || !this.firstnameIsValid) return;
      this.$emit("modal-ok", this.contactLocal);
      this.showLocal = false;
    },
    modalClosed() {
      this.$emit("modal-closed");
      this.showLocal = false;
    },
    async fetchCompanies() {
      this.companies = await companyClient.getCompanies();
    },
    validateFirstname() {
      if (!this.contactLocal.firstname) {
        this.firstnameIsValid = false;
      } else {
        this.firstnameIsValid = true;
      }
    },
    validateLastname() {
      if (!this.contactLocal.lastname) {
        this.lastnameIsValid = false;
      } else {
        this.lastnameIsValid = true;
      }
    },
  },
  data() {
    return {
      showLocal: this.show,
      contactLocal: this.contact,
      companies: null,
      firstnameIsValid: false,
      lastnameIsValid: false,
      customValidationOn: true,
    };
  },
  created() {
    this.fetchCompanies();
    this.validateFirstname();
    this.validateLastname();
  },
  mounted() {},
};
</script>
