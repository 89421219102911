<template>
  <mdb-container class="grey lighten-5">
    <mdb-row>
      <mdb-col>{{ $t("home.welcome") }}</mdb-col>
    </mdb-row>
    <mdb-row class="float-right">
      <mdb-btn
        outline="elegant"
        style="color: white !important"
        size="sm"
        @click="login"
        >{{ $t("login.login") }}</mdb-btn
      >
    </mdb-row>
  </mdb-container>
</template>

<script>
//import users_image from "@/assets/img/users.svg";
//import EventBasic from "@/components/events/EventBasic.vue";
import { mdbRow, mdbCol, mdbContainer, mdbBtn } from "mdbvue";
import * as msClient from "@/client/MSClient.js";
export default {
  name: "Welcome",
  props: [],
  components: {
    mdbRow,
    mdbCol,
    mdbContainer,
    mdbBtn,
  },
  mounted() {},
  methods: {
    login() {
      msClient.signInMsUser();
    },
  },
  data: () => ({}),
};
</script>

<style></style>
