<template>
  <mdb-container class="grey lighten-5">
    <mdb-row class="justify-content-end">
      <mdb-btn outline="elegant" @click="openSite">
        {{ $t("material.open_site") }}
      </mdb-btn>
      <mdb-btn outline="elegant" @click="refresh">
        {{ $t("material.refresh") }}
      </mdb-btn>
    </mdb-row>
    <mdb-row class="justify-content-center">
      <div v-if="(!materials || materials.length == 0) && !loading">
        {{ $t("material.no_materials_found") }}
      </div>
    </mdb-row>
    <mdb-row class="justify-content-center" v-if="loading">
      <mdb-spinner />
    </mdb-row>
    <mdb-row class="justify-content-center" v-if="showInitializationMessage">
      {{ $t("material.initiating_root_folder") }}
    </mdb-row>
    <div v-if="materials && materials.length > 0">
      <mdb-row
        v-for="(material, index) in materials"
        v-bind:index="index"
        v-bind:key="index"
      >
        <mdb-col>
          <p>
            <a :href="material.webUrl" target="_new">{{ material.name }}</a>
          </p>
        </mdb-col>
      </mdb-row>
    </div>
  </mdb-container>
</template>

<script>
import * as materialClient from "@/client/MaterialClient.js";
import { mdbContainer, mdbRow, mdbCol, mdbBtn, mdbSpinner } from "mdbvue";
export default {
  name: "EventMaterials",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSpinner,
  },
  created: function () {},
  mounted() {
    this.fetchFolderMaterials();
  },
  data() {
    return {
      materials: [],
      materialSiteUrl: null,
      loading: false,
      showInitializationMessage: false,
    };
  },
  methods: {
    async fetchFolderMaterials() {
      this.loading = true;
      //const eventId = this.$route.params.eventId;
      const eventId = this.$store.getters.getEvent.id;
      var payload;
      if ((payload = await materialClient.getEventRootFolder(eventId))) {
        if (!payload.materials || payload.materials.length == 0) {
          console.log("No event rootfolder found; initializing....");
          this.showInitializationMessage = true;
          await materialClient.createEventRootFolder(eventId);
          this.fetchFolderMaterials();
        } else {
          this.materials = payload.materials;
          this.materialSiteUrl = payload.materialSiteUrl;
          this.loading = false;
          this.showInitializationMessage = false;
        }
      }
    },
    async refresh() {
      this.fetchFolderMaterials();
    },
    openSite() {
      window.open(this.materialSiteUrl, "_blank");
    },
  },
};
</script>

<style></style>
