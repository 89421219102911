import { getApi, putApi, postApi, deleteApi } from "@/client/AxiosClient.js";
import i18n from "@/lang/lang.js";
import store from "@/store/index.js";

export async function getContacts() {
  return await getApi({
    url: `${store.getters.getUser.email}/contacts`,
    errorMsg: i18n.t("contact.load_not_success"),
  });
}

export async function saveContact(body) {
  return await postApi({
    url: `${store.getters.getUser.email}/contacts`,
    body,
    errorMsg: i18n.t("contact.create_not_success"),
  });
}

export async function updateContact(body) {
  return await putApi({
    url: `${store.getters.getUser.email}/contacts/${body.id}`,
    body,
    errorMsg: i18n.t("contact.update_not_success"),
  });
}

export async function deleteContact(id) {
  return await deleteApi({
    url: `${store.getters.getUser.email}/contacts/${id}`,
    errorMsg: i18n.t("contact.delete_not_success"),
  });
}
