import store from "@/store/index.js";
import { PublicClientApplication } from "@azure/msal-browser";
import * as msal from "msal";

const redirectUri = window.location.hostname.includes("localhost")
  ? "http://localhost:3000"
  : "https://events.tapahtumabooster.fi/";

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: "eec7fed1-2390-468c-844d-bfb30aeda684",
    authority:
      "https://login.microsoftonline.com/b917ca14-337a-4140-a300-2a23e82c1390",
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            //console.info(message);
            return;
          case msal.LogLevel.Verbose:
            //console.debug(message);
            return;
          case msal.LogLevel.Warning:
            //console.warn(message);
            return;
        }
      },
    },
  },
});

function getAllAccounts() {
  return msalInstance.getAllAccounts();
}

function getFirstAccount() {
  const accounts = getAllAccounts();
  if (accounts && accounts.length > 0) {
    return accounts[0];
  } else {
    return null;
  }
}

async function signInMsUser() {
  const response = await msalInstance.loginPopup({
    scopes: ["api://bf3aec6b-525c-467c-84af-10e02859c46c/access_as_user"],
  });
  try {
    store.commit("updateUser", {
      accessToken: response.accessToken,
      email: response.account.username,
      userName: response.account.name,
    });
  } catch (error) {
    console.error(`error during authentication: ${error}`);
    return false;
  }
  return true;
}

async function signOutMsUser() {
  await msalInstance.logoutPopup({
    account: getFirstAccount(),
    mainWindowRedirectUri: "",
  });
  store.commit("updateUser", {});
}

export { signInMsUser, signOutMsUser, getAllAccounts };
