<template>
  <div class="d-flex align-items-center">
    <span v-if="showme" class="font-italic" :class="[{ fadeIn: showme }]">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Eventhandler",
  props: {
    text: String,
    callback: Function,
    waitTime: {
      format: Number,
      default: 10000,
    },
  },
  components: {},
  methods: {
    async start() {
      if (!this.ready) {
        //console.log("Not yet ready to handle events!");
        return;
      }
      if (this.called) {
        //console.log("Saving already scheduled!");
        return;
      }
      this.called = true;
      await new Promise((resolve) => setTimeout(resolve, this.waitTime));
      this.showme = true;
      //console.log("Calling callback");
      if (await this.callback()) {
        //console.log("Finished callback");
        // show the label for a while in case of callback is very quick
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.showme = false;
        this.called = false;
      } else {
        //console.log("callback failed");
        this.showme = false;
        this.called = false;
      }
    },
    async setReady() {
      // wait for a while so that the component using this eventhandler has initiated all field values
      // and not causing unnecessary saving events
      await new Promise((resolve) => setTimeout(resolve, 2000));
      //console.log("Now ready to handle events");
      this.ready = true;
      this.called = false;
    },
  },
  mounted() {
    this.setReady();
  },
  data: () => ({
    showme: false,
    called: false,
    ready: false,
  }),
};
</script>

<style></style>
