<template>
  <mdb-container class="grey lighten-5">
    <mdb-row class="justify-content-end">
      <DataTableButtons
        :addText="$t('note.add')"
        :modifyText="$t('note.modify')"
        :deleteText="$t('note.delete')"
        :refreshText="$t('note.refresh')"
        :disabled="selectedNote === undefined"
        v-on:addEvent="addingNote"
        v-on:modifyEvent="modifyingNote"
        v-on:deleteEvent="onDelete"
        v-on:refreshEvent="refreshingNotes"
      />
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-datatable-2
          v-if="notes.rows.length > 0"
          v-model="notes"
          selectable
          @selected="selectingNote"
          :searching="{ value: search }"
          hover
          selectColor="indigo lighten-3"
        />
      </mdb-col>
    </mdb-row>
    <EditNoteModal
      :note="selectedNote"
      :show="showAddNewModal"
      :key="componentKey"
      v-on:modal-ok="savingNote"
      v-on:modal-closed="closingModal"
    />
  </mdb-container>
</template>

<script>
import * as noteClient from "@/client/NoteClient.js";
import { mdbContainer, mdbRow, mdbCol, mdbDatatable2 } from "mdbvue";
import DataTableButtons from "@/components/DataTableButtons.vue";
import EditNoteModal from "@/components/notes/EditNoteModal.vue";
export default {
  name: "EventNotes",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbDatatable2,
    DataTableButtons,
    EditNoteModal,
  },
  created: function () {
    this.fetchingNotes();
  },
  mounted() {
    this.notes = {
      rows: [],
      columns: this.columns,
    };
  },
  data() {
    return {
      showAddNewModal: false,
      componentKey: 0,
      noteLocal: null,
      notesLocal: null,
      selectedNote: undefined,
      search: "",
      columns: [
        {
          label: this.$t("note.user"),
          field: "userId",
          sort: true,
        },
        {
          label: this.$t("note.content"),
          field: "content",
          format: (value) => value.substring(0, 50),
          sort: true,
        },
        {
          label: this.$t("note.date"),
          field: "noteDate",
          sort: true,
        },
      ],
      notes: {
        rows: [],
        columns: [],
      },
    };
  },
  methods: {
    selectingNote(val) {
      this.selectedNote = val;
    },
    closingModal() {
      this.fetchingNotes();
    },
    refreshingNotes() {
      this.fetchingNotes();
    },
    async fetchingNotes() {
      this.notes = {
        columns: this.columns,
        rows: [],
      };
      if (
        (this.localNotes = await noteClient.getNotes(
          this.$store.getters.getEvent.id
        ))
      ) {
        this.notes = {
          columns: this.columns,
          rows: this.localNotes,
        };
      }
    },
    async savingNote(note) {
      this.showAddNewModal = false;
      const eventId = this.$store.getters.getEvent.id;
      if (!note.userId || note.userId == "")
        note.userId = this.$store.getters.getUser.email;
      note.eventId = eventId;
      if (await noteClient.saveNote(eventId, note)) {
        this.fetchingNotes();
        this.notifySuccess(this.$t("note.save_success"));
      }
    },
    showModal() {
      this.componentKey += 1;
      this.showAddNewModal = true;
    },
    modifyingNote() {
      this.showModal();
    },
    addingNote() {
      this.selectedNote = {
        userId: this.$store.getters.getUser.email,
        noteDate: "",
      };
      this.showModal();
    },
    async onDelete() {
      this.$confirmablePopup.show({
        text: this.$t("event.note.confirm_delete"),
        onConfirm: () => {
          this.deleteNote();
        },
      });
    },
    async deleteNote() {
      if (
        await noteClient.deleteNote(
          this.$store.getters.getEvent.id,
          this.selectedNote.id
        )
      ) {
        this.fetchingNotes();
        this.notifySuccess(this.$t("note.delete_success"));
      }
    },
  },
};
</script>

<style></style>
