import { getApi, putApi, postApi, deleteApi } from "@/client/AxiosClient.js";
import i18n from "@/lang/lang.js";
import store from "@/store/index.js";

export async function getCompanies() {
  return await getApi({
    url: `${store.getters.getUser.email}/companies`,
    errorMsg: i18n.t("company.load_not_success"),
  });
}

export async function saveCompany(body) {
  return await postApi({
    url: `${store.getters.getUser.email}/companies`,
    body,
    errorMsg: i18n.t("company.create_not_success"),
  });
}
export async function updateCompany(body) {
  return await putApi({
    url: `${store.getters.getUser.email}/companies/${body.id}`,
    body,
    errorMsg: i18n.t("company.update_not_success"),
  });
}

export async function deleteCompany(id) {
  return await deleteApi({
    url: `${store.getters.getUser.email}/companies/${id}`,
    errorMsg: i18n.t("company.delete_not_success"),
  });
}
