import { getApi, postApi } from "@/client/AxiosClient.js";
import i18n from "@/lang/lang.js";

export async function getUserCategories() {
  return await getApi({
    url: "usercategories",
    errorMsg: i18n.t("user.category_load_not_success"),
  });
}

export async function getUserRoles() {
  return await getApi({
    url: "userroles",
    errorMsg: i18n.t("user.role_load_not_success"),
  });
}

export async function getUser(userId) {
  return await getApi({
    url: userId,
    errorMsg: i18n.t("user.getting_user_failed"),
  });
}

export async function getUsers() {
  return await getApi({
    url: "",
    errorMsg: i18n.t("user.getting_users_failed"),
  });
}

export async function createUser(userid, firstname, lastname) {
  return await postApi({
    url: userid,
    body: {
      firstname: firstname,
      lastname: lastname,
    },
    errorMsg: i18n.t("user.creating_user_failed"),
  });
}
