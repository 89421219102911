<template>
  <div id="app">
    <SideNav />
    <ConfirmablePopup />
    <InfoPopup />
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
export default {
  name: "App",
  components: {
    SideNav,
  },
  mounted() {},
};
</script>

<style>
/*@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");*/
@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(./assets/AvenirLTStd-Book.otf) format("opentype");
}

#app {
  font-family: "Avenir", "Roboto", sans-serif;
  text-align: center;
  margin-top: 1rem;
}

body {
  background-color: #424242;
  background-image: url(./assets/img/booster_pysty_body.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
</style>
