<template>
  <mdb-container>
    <mdb-modal
      :show="showLocal"
      @close="modalClosed()"
      size="lg"
      removeBackdrop
    >
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100">{{
          $t("company.information")
        }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="mx-3 grey-text">
        <mdb-input
          v-model="company.companyId"
          :label="$t('company.companyid')"
        />
        <mdb-input
          v-model="company.name"
          :label="$t('company.name')"
          @blur="validateName"
          :customValidation="customValidationOn"
          validFeedback=" "
          :isValid="nameIsValid"
          :invalidFeedback="this.$t('company.give_a_name')"
        />
        <mdb-input
          v-model="company.eInvoiceAddress"
          :label="$t('company.einvoiceaddress')"
        />
        <mdb-input
          v-model="company.eInvoiceOperatorId"
          :label="$t('company.einvoiceoperatorid')"
        />
        <mdb-input
          v-model="company.eInvoiceIntermediator"
          :label="$t('company.einvoiceintermediator')"
        />
        <mdb-input
          v-model="company.eInvoiceEmail"
          :label="$t('company.einvoiceemail')"
        />
        <mdb-input
          :label="$t('company.notes')"
          type="textarea"
          v-model="company.notes"
        />
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn @click="saveCompany()" outline="elegant">
          {{ $t("company.save") }}
        </mdb-btn>
        <mdb-btn @click="modalClosed()" outline="elegant">
          {{ $t("company.quit") }}
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbInput,
  mdbModalFooter,
} from "mdbvue";
export default {
  name: "ModalExamplesPage",
  components: {
    mdbContainer,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbInput,
    mdbModalFooter,
    mdbModalTitle,
  },
  props: {
    show: Boolean,
    company: Object,
  },
  methods: {
    saveCompany() {
      if (!this.nameIsValid) return;
      this.$emit("modal-ok", this.company);
      this.showLocal = false;
    },
    modalClosed() {
      this.$emit("modal-closed");
      this.showLocal = false;
    },
    validateName() {
      if (!this.company.name || this.company.name == "") {
        this.nameIsValid = false;
      } else {
        this.nameIsValid = true;
      }
    },
  },
  data() {
    return {
      showLocal: this.show,
      customValidationOn: true,
      nameIsValid: false,
    };
  },
  mounted() {
    this.validateName();
  },
  computed: {},
};
</script>
