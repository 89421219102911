export default class Runplan {
  constructor(
    date = new Date().toISOString().slice(0, 10),
    group = "Ei ryhmässä",
    from = "12:00",
    duration = 60,
    to = "13:00",
    location = "",
    notes = "notes",
    media = "media",
    light = "light",
    sound = "sound",
    responsible = "",
    appvisibility = false
  ) {
    (this.date = date),
      (this.group = group),
      (this.from = from),
      (this.duration = duration),
      (this.to = to),
      (this.location = location),
      (this.notes = notes),
      (this.media = media),
      (this.light = light),
      (this.sound = sound),
      (this.responsible = responsible),
      (this.appvisibility = appvisibility);
  }
}
