import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbvue/lib/css/mdb.min.css";
import "@/assets/css/global.css";
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import i18n from "./lang/lang";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { ConfirmablePopup, InfoPopup } from "./plugins/popup.js";

import VModal from "vue-js-modal";

Vue.use(ConfirmablePopup);
Vue.use(InfoPopup);
Vue.use(VModal);

import credentials from "./firebase/credentials";

Vue.config.productionTip = false;
Vue.config.devtools = true;

import Notify from "mdbvue/lib/components/Notify";
Vue.use(Notify);

import axios from "axios";
import VueAxios from "vue-axios";
axios.defaults.withCredentials = false;
Vue.use(VueAxios, axios);

import Vuex from "vuex";
Vue.use(Vuex);

const moment = require("moment");

if (navigator.language.startsWith("fi")) {
  require("moment/locale/fi");
} else {
  require("moment/locale/es-us");
}

Vue.use(require("vue-moment"), {
  moment,
});

const app = initializeApp(credentials.firebaseConfig);
getAnalytics(app);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
