import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home";
import Login from "@/views/Login";
import CompaniesView from "@/views/CompaniesView";
import ContactsView from "@/views/ContactsView";
import UsersView from "@/views/UsersView";
import LocationsView from "@/views/LocationsView";
import EventEditorView from "@/views/EventEditorView";

import store from "@/store/index.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/editevent/:eventId",
      name: "EditEvent",
      component: EventEditorView,
    },
    {
      path: "/companies",
      name: "Companies",
      component: CompaniesView,
    },
    {
      path: "/contacts",
      name: "Contacts",
      component: ContactsView,
    },
    {
      path: "/users",
      name: "Users",
      component: UsersView,
    },
    {
      path: "/locations",
      name: "Locations",
      component: LocationsView,
    },
    {
      path: "/eventeditor",
      name: "EventEditor",
      component: EventEditorView,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Home" && !store.getters.isAuthenticated)
    next({ name: "Home" });
  else next();
});

export default router;
