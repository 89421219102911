<template>
  <div>
    <mdb-btn
      outline="elegant"
      v-if="exportText !== undefined"
      @click="exportEvent"
      >{{ exportText }}
    </mdb-btn>
    <mdb-btn
      outline="elegant"
      v-if="exportText !== undefined"
      @click="refreshEvent"
      >{{ refreshText }}</mdb-btn
    >
    <mdb-btn :disabled="disabled" outline="elegant" @click="modifyEvent">{{
      modifyText
    }}</mdb-btn>
    <mdb-btn :disabled="disabled" outline="elegant" @click="deleteEvent">{{
      deleteText
    }}</mdb-btn>
    <mdb-btn outline="elegant" @click="addEvent">{{ addText }}</mdb-btn>
  </div>
</template>

<script>
import { mdbBtn } from "mdbvue";
export default {
  name: "DataTableButtons",
  components: {
    mdbBtn,
  },
  props: {
    exportText: String,
    modifyText: String,
    deleteText: String,
    addText: String,
    refreshText: String,
    disabled: Boolean,
  },
  created: function () {},
  mounted() {},
  data() {
    return {};
  },
  methods: {
    addEvent() {
      this.$emit("addEvent");
    },
    deleteEvent() {
      this.$emit("deleteEvent");
    },
    modifyEvent() {
      this.$emit("modifyEvent");
    },
    refreshEvent() {
      this.$emit("refreshEvent");
    },
    exportEvent() {
      this.$emit("exportEvent");
    },
  },
};
</script>
