<template>
  <mdb-container class="grey lighten-5">
    <mdb-row class="justify-content-end">
      <mdb-col col="3">
        <mdb-input v-model="search" bg :label="$t('contact.search')" />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-end">
      <DataTableButtons
        :exportText="$t('contact.export')"
        :addText="$t('contact.add')"
        :modifyText="$t('contact.modify')"
        :deleteText="$t('contact.delete')"
        :refreshText="$t('contact.refresh')"
        :disabled="selectedContact === undefined"
        v-on:addEvent="addingContact"
        v-on:modifyEvent="modifyingContact"
        v-on:deleteEvent="onDelete"
        v-on:refreshEvent="refreshingContacts"
        v-on:exportEvent="exportingContacts"
      />
    </mdb-row>
    <mdb-row>
      <mdb-col
        ><mdb-datatable-2
          v-if="contacts.rows.length > 0"
          v-model="contacts"
          selectable
          @selected="selectingContact"
          :searching="{ value: search }"
          hover
          selectColor="indigo lighten-3"
        />
      </mdb-col>
    </mdb-row>
    <EditContactModal
      :contact="localContact"
      :show="showAddNewModal"
      :key="componentKey"
      v-on:modal-ok="savingContact"
      v-on:modal-closed="closingContact"
      v-if="localContact != null"
    />
  </mdb-container>
</template>

<script>
import * as contactApi from "@/client/ContactClient.js";
import Contact from "@/model/Contact.js";
import DataTableButtons from "@/components/DataTableButtons.vue";
import EditContactModal from "@/components/customers/EditContactModal.vue";
import { mdbContainer, mdbRow, mdbCol, mdbInput, mdbDatatable2 } from "mdbvue";
export default {
  name: "Contacts",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbDatatable2,
    EditContactModal,
    DataTableButtons,
  },
  methods: {
    async savingContact(val) {
      if (!val.id) {
        if (await contactApi.saveContact(val)) {
          this.notifySuccess(this.$t("contact.create_success"));
          this.refreshingContacts();
        }
      } else {
        if (await contactApi.updateContact(val)) {
          this.notifySuccess(this.$t("contact.update_success"));
          this.refreshingContacts();
        }
      }
    },
    showModal(contact) {
      this.localContact = contact;
      this.componentKey += 1;
      this.showAddNewModal = true;
    },
    closingContact() {
      this.fetchingContacts();
    },
    refreshingContacts() {
      this.fetchingContacts();
    },
    exportingContacts() {
      this.createCSV(this.localContacts, "Contacts.csv");
    },
    addingContact() {
      this.contact = new Contact();
      this.showModal(this.contact);
    },
    selectingContact(val) {
      this.selectedContact = val;
    },
    onDelete() {
      this.$confirmablePopup.show({
        text: this.$t("contact.confirm_delete"),
        onConfirm: () => {
          this.deletingContact();
        },
      });
    },
    async deletingContact() {
      await contactApi.deleteContact(this.selectedContact.id);
      this.fetchingContacts();
      this.notifySuccess(this.$t("contact.delete_success"));
    },
    modifyingContact() {
      this.showModal(this.selectedContact);
    },
    async fetchingContacts() {
      this.contacts = {
        columns: this.columns,
        rows: [],
      };
      if ((this.localContacts = await contactApi.getContacts())) {
        this.contacts = {
          columns: this.columns,
          rows: this.localContacts,
        };
      }
    },
  },
  created() {
    this.fetchingContacts();
  },
  data() {
    return {
      selectedContact: undefined,
      localContacts: [],
      localContact: null,
      componentKey: 0,
      showAddNewModal: false,
      search: "",
      columns: [
        {
          label: this.$t("contact.firstname"),
          field: "firstname",
          sort: true,
        },
        {
          label: this.$t("contact.lastname"),
          field: "lastname",
          sort: true,
        },
        {
          label: this.$t("contact.title"),
          field: "title",
          sort: true,
        },
        {
          label: this.$t("contact.email"),
          field: "email",
          sort: true,
        },
        {
          label: this.$t("contact.phonenumber"),
          field: "phonenumber",
          sort: true,
        },
        {
          label: this.$t("contact.streetAddress"),
          field: "streetAddress",
          sort: true,
        },
        {
          label: this.$t("contact.postalcode"),
          field: "postalcode",
          sort: true,
        },
        {
          label: this.$t("contact.city"),
          field: "city",
          sort: true,
        },
        {
          label: this.$t("contact.notes"),
          field: "notes",
          sort: true,
        },
      ],
      contacts: {
        rows: [],
        columns: this.columns,
      },
    };
  },
};
</script>

<style></style>
