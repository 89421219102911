import { getApi, postApi } from "@/client/AxiosClient.js";
import i18n from "@/lang/lang.js";
import store from "@/store/index.js";

export async function getEventRootFolder(eventId) {
  return await getApi({
    url: `${store.getters.getUser.email}/events/${eventId}/materials`,
    errorMsg: i18n.t("event.getting_rootfolder_not_success"),
  });
}

export async function createEventRootFolder(eventId) {
  return await postApi({
    url: `${store.getters.getUser.email}/events/${eventId}/materials`,
    body: {
      name: "TUOTANTOKANSIO",
      type: "folder",
      isRoot: true,
    },
    errorMsg: i18n.t("event.creating_rootfolder_not_success"),
  });
}

export async function getMaterial(eventId, materialid) {
  return await getApi({
    url: `${store.getters.getUser.email}/events/${eventId}/materials/${materialid}`,
    errorMsg: i18n.t("event.getting_material_not_success"),
  });
}

export async function getProductionPlan(eventId) {
  return await getApi({
    url: `${store.getters.getUser.email}/events/${eventId}/materials/productionplan`,
    errorMsg: "", //i18n.t("event.getting_productionplan_not_success")
  });
}
