export default class Location {
  constructor(
    name = "",
    locationLon = "",
    locationLat = "",
    streetAddress = "",
    postalcode = "",
    city = "",
    parentLocationId = ""
  ) {
    this.name = name;
    this.locationLon = locationLon;
    this.locationLat = locationLat;
    this.streetAddress = streetAddress;
    this.postalcode = postalcode;
    this.city = city;
    this.parentLocationId = parentLocationId;
  }
}
