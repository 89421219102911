import { getApi, postApi, putApi, deleteApi } from "@/client/AxiosClient.js";
import i18n from "@/lang/lang.js";
import store from "@/store/index.js";

export async function getRunplan(eventId) {
  return await getApi({
    url: `${store.getters.getUser.email}/events/${eventId}/runplans`,
    errorMsg: i18n.t("runplan.load_not_success"),
  });
}

export async function saveRunplan(eventId, body) {
  return await postApi({
    url: `${store.getters.getUser.email}/events/${eventId}/runplans`,
    body,
    errorMsg: i18n.t("runplan.create_not_success"),
  });
}

export async function updateRunplan(eventId, body) {
  return await putApi({
    url: `${store.getters.getUser.email}/events/${eventId}/runplans/${body.id}`,
    body,
    errorMsg: i18n.t("runplan.create_not_success"),
  });
}

export async function deleteRunplan(eventId) {
  return await deleteApi({
    url: `${store.getters.getUser.email}/events/${eventId}/runplans`,
    errorMsg: i18n.t("runplan.delete_not_success"),
  });
}
