<template>
  <div v-if="params !== undefined">
    <mdb-select
      style="width: 250px"
      search
      top
      :show="toggleShow"
      v-model="items"
      label=""
      :visibleOptions="6"
      @getValue="handleChange"
    />
  </div>
</template>

<script>
import { mdbSelect } from "mdbvue";
export default {
  name: "AgSelect",
  components: {
    mdbSelect,
  },
  data() {
    return {
      toggleShow: true,
      items: [],
    };
  },
  created() {
    console.log("LOADING " + this.params.selectOption);
    if (this.params.selectOption === "location") {
      this.items = this.$store.getters.getRunplanLocations;
      console.log("LOADED LOCATIONS: " + JSON.stringify(this.items));
    } else if (this.params.selectOption === "responsible") {
      this.items = [...this.$store.getters.getRunplanResponsibles];
      Array.prototype.push.apply(
        this.items,
        this.$store.getters.getRunplanUsers
      );
      console.log("LOADED RESPOS: " + JSON.stringify(this.items));
    }
  },
  methods: {
    handleChange(value, text) {
      if (value) {
        this.params.api.stopEditing();
        this.params.data[this.params.selectOption] = text;
        console.log(value);
      }
    },
  },
};
</script>

<style scoped>
.md-form {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
