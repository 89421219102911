<template>
  <mdb-container fluid>
    <mdb-row v-if="productionPlan">
      <ExcelFrame :excel="productionPlan.embedUrl" />
    </mdb-row>
    <mdb-row v-if="!productionPlan">
      Tuotantosuunnitelmaa ei ole muodostettu
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow } from "mdbvue";
import ExcelFrame from "@/components/ExcelFrame.vue";
import * as materialClient from "@/client/MaterialClient.js";
export default {
  name: "EventProductionPlan",
  components: {
    mdbContainer,
    mdbRow,
    ExcelFrame,
  },
  created: function () {},
  mounted() {
    this.fetchProductionplan(this.$route.params.eventId);
  },
  data() {
    return {
      productionPlan: null,
    };
  },
  methods: {
    async fetchProductionplan(eventId) {
      this.productionPlan = await materialClient.getProductionPlan(eventId);
    },
  },
};
</script>

<style></style>
