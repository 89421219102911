<template>
  <mdb-container>
    <mdb-modal
      :show="showLocal"
      @close="showLocal = false"
      size="lg"
      :frame="frameStyle"
      removeBackdrop
    >
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100">{{
          $t("location.information")
        }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="mx-3 grey-text">
        <GooglePlacesAutoCompleteJS v-on:place-result="placeResult" />
        <mdb-input
          v-model="location.name"
          :label="$t('location.name')"
          :invalidFeedback="this.$t('location.give_a_name')"
          @blur="checkName"
          :customValidation="customValidationOn"
          :isValid="nameIsValid"
          validFeedback=" "
        />
        <mdb-input
          v-model="location.streetAddress"
          :label="$t('location.streetAddress')"
        />
        <mdb-input
          v-model="location.postalcode"
          :label="$t('location.postalcode')"
        />
        <mdb-input v-model="location.city" :label="$t('location.city')" />
        <mdb-input
          v-model="location.locationLon"
          :label="$t('location.locationLon')"
        />
        <mdb-input
          v-model="location.locationLat"
          :label="$t('location.locationLat')"
        />
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn @click="saveLocation()" outline="elegant">
          {{ $t("location.save") }}
        </mdb-btn>
        <mdb-btn @click="showLocal = false" outline="elegant">
          {{ $t("location.quit") }}
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbInput,
  mdbModalFooter,
} from "mdbvue";
import GooglePlacesAutoCompleteJS from "@/components/GooglePlacesAutoCompleteJS.vue";
export default {
  name: "ModalExamplesPage",
  components: {
    mdbContainer,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbInput,
    mdbModalFooter,
    mdbModalTitle,
    GooglePlacesAutoCompleteJS,
  },
  props: {
    show: Boolean,
    frameStyle: Boolean,
    location: Object,
  },
  mounted() {
    this.checkName();
  },
  methods: {
    checkName() {
      if (this.locationLocal.name && this.locationLocal.name != "") {
        this.nameIsValid = true;
      } else {
        this.nameIsValid = false;
      }
    },
    placeResult(val) {
      this.locationLocal.name = val.name;
      this.nameIsValid = true;

      this.locationLocal.streetAddress = val.streetaddress;
      this.locationLocal.postalcode = val.postalcode;
      this.locationLocal.locationLat = val.lat;
      this.locationLocal.locationLon = val.lng;
      this.locationLocal.city = val.city;
    },
    saveLocation() {
      if (!this.nameIsValid) return;
      this.$emit("modal-ok", this.locationLocal);
      this.showLocal = false;
    },
  },
  data() {
    return {
      showLocal: this.show,
      locationLocal: this.location,
      customValidationOn: true,
      nameIsValid: false,
    };
  },
};
</script>
