<template>
  <mdb-side-nav-2
    :data="navigation"
    v-model="show"
    push
    :slim-collapsed="slimCollapsed"
    expand-on-hover
    slim
    @toggleSlim="slimCollapsed = $event"
  >
    <div slot="header">
      <img :src="einarilogo" class="" height="80%" width="80%" />
      <br /><br />
      <LoginMsal
        v-if="$store.getters.isAuthenticated"
        :slimCollapsed="slimCollapsed"
      />
    </div>
    <main slot="main">
      <div>
        <router-view></router-view>
      </div>
    </main>
    <footer slot="footer"></footer>
  </mdb-side-nav-2>
</template>

<script>
import { mdbSideNav2 } from "mdbvue";
import LoginMsal from "@/components/logins/LoginMsal";
import user_image from "@/assets/img/user.svg";
import einarilogo from "@/assets/img/einarITpng.png";

export default {
  components: {
    mdbSideNav2,
    LoginMsal,
  },
  data() {
    return {
      user_image,
      einarilogo,
      show: true,
      slimCollapsed: true,
      user: null,
      navigation: [
        {
          name: this.$t("nav.home"),
          to: "/",
          icon: "home",
        },
        {
          name: this.$t("nav.companies"),
          to: "/companies",
          icon: "building",
        },
        {
          name: this.$t("nav.contacts"),
          to: "/contacts",
          icon: "address-book",
        },
        {
          name: this.$t("nav.locations"),
          to: "/locations",
          icon: "map",
        },
        {
          name: this.$t("nav.users"),
          to: "/users",
          icon: "users",
        },
      ],
    };
  },
};
</script>
