export default {
  firebaseConfig: {
    apiKey: "AIzaSyBjK4ZiXuY7qI_gifukXAHf46P5SKFHZ70",
    authDomain: "event-production-einari.firebaseapp.com",
    projectId: "event-production-einari",
    storageBucket: "event-production-einari.appspot.com",
    messagingSenderId: "536632214329",
    appId: "1:536632214329:web:a10f771b119e6f7fd51ee8",
    measurementId: "G-2V8L3DVQG6",
  },
};
