<template>
  <mdb-container>
    <mdb-row>
      <mdb-col>
        <MazPhoneNumberInput
          v-model="phoneNumber"
          @update="validatePhoneNumber"
          default-country-code="FI"
          :only-countries="['FI', 'SE', 'NO']"
          size="lg"
          :noExample="true"
          :translations="{
            countrySelectorLabel: this.$t('login.countryselectorlabel'),
            countrySelectorError: this.$t('login.countryselectorerror'),
            countrySelectorSearchPlaceholder: this.$t('login.countrysearch'),
            phoneNumberLabel: this.$t('login.phonenumberlabel'),
            example: this.$t('login.phonenumberexample'),
          }"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <div id="div-recaptcha"></div>
      </mdb-col>
    </mdb-row>
    <mdb-row class="">
      <mdb-col class="">
        <mdb-btn
          :disabled="validPhoneNumberNotExists || recaptchaNotVerified"
          class=""
          color="elegant"
          size="block"
          @click="startVerification()"
          >{{ $t("login.start_verification") }}</mdb-btn
        >
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-input
          v-if="confirmationResult !== null"
          v-model="verificationCode"
          @change="confirmCode"
          type="text"
          label="Large input"
          bg
          size="lg"
          :max="10"
        />
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { MazPhoneNumberInput } from "maz-ui";
import { mdbContainer, mdbRow, mdbCol, mdbBtn, mdbInput } from "mdbvue";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

export default {
  name: "LoginPhone",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbInput,
    MazPhoneNumberInput,
  },
  props: { content: Object },
  data() {
    return {
      phoneNumber: null,
      validPhoneNumberNotExists: true,
      recaptchaNotVerified: false,
      auth: null,
      recaptchaVerifier: null,
      recaptchaWidgetId: null,
      confirmationResult: null,
      verificationCode: "",
      urlVerifyToken: "verifytoken",
      body: "",
    };
  },
  methods: {
    confirmAuthorization(token, uid) {
      this.body = { token: token, uid: uid };
      this.postapi(this.urlVerifyToken, this.body).then((res) => {
        switch (res) {
          case 200:
            break;

          default:
            break;
        }
      });
    },
    confirmCode() {
      this.verificationCode = this.verificationCode.replace(/\D/g, "");
      if (this.verificationCode.length < 6) return;
      this.confirmationResult
        .confirm(this.verificationCode)
        .then((result) => {
          this.confirmAuthorization(result.user.accessToken, result.user.uid);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validatePhoneNumber(val) {
      this.validPhoneNumberNotExists = !val.isValid;
    },
    startVerification() {
      signInWithPhoneNumber(this.auth, this.phoneNumber, this.recaptchaVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setupFirebase() {
      this.auth = getAuth();
      this.auth.languageCode = "fi";
      this.recaptchaVerifier = new RecaptchaVerifier(
        "div-recaptcha",
        {
          size: "normal",
          callback: (response) => {
            console.log(response);
            this.recaptchaVerified = false;
          },
          "expired-callback": () => {
            this.recaptchaVerified = false;
          },
        },
        this.auth
      );
      this.recaptchaVerifier.render().then((widgetId) => {
        this.recaptchaWidgetId = widgetId;
      });
    },
  },
  mounted() {
    this.setupFirebase();
  },

  computed: {},
};
</script>

<style></style>
