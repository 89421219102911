<template>
  <div>
    <HeaderBase :title="$t('contact.contacts')" />
    <Contacts />
  </div>
</template>

<script>
import HeaderBase from "@/components/HeaderBase.vue";
import Contacts from "@/components/customers/Contacts.vue";
export default {
  name: "ContactsView",
  components: {
    HeaderBase,
    Contacts,
  },
};
</script>

<style></style>
