<template>
  <mdb-container class="m-5">
    <mdb-row tag="h4">
      <div @click="showLocations = !showLocations" style="cursor: pointer">
        {{
          $t("location.locations") +
          " " +
          (showLocations ? $t("location.hide") : $t("location.show"))
        }}
      </div>
    </mdb-row>
    <div v-if="showLocations">
      <mdb-row>
        <mdb-col>
          <mdb-btn
            class="float-right"
            size="sm"
            outline="elegant"
            @click="showLocationSelector = true"
            >{{ $t("event.add_new_location") }}</mdb-btn
          >
        </mdb-col>
      </mdb-row>
      <draggable @change="afterDrag" v-model="eventLocations" handle=".handle">
        <mdb-row
          v-for="(location, index) in eventLocations"
          v-bind:index="index"
          v-bind:key="index"
        >
          <mdb-container class="mb-2 border border-dark shadow">
            <div
              class="handle"
              style="cursor: pointer; display: flex; justify-content: flex-end"
            >
              &#8597;
            </div>
            <EventLocation
              :location="location"
              :eventId="eventId"
              @onDelete="onDelete"
            />
          </mdb-container>
        </mdb-row>
      </draggable>
      <mdb-modal
        :show="showLocationSelector"
        @close="showLocationSelector = false"
        size="lg"
      >
        <LocationSelector
          v-on:closed="showLocationSelector = false"
          v-on:locationsSelected="addLocations"
        />
      </mdb-modal>
    </div>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbBtn, mdbCol, mdbModal } from "mdbvue";
import draggable from "vuedraggable";
import EventLocation from "@/components/events/EventLocation.vue";
import LocationSelector from "@/components/locations/LocationSelector.vue";
import * as eventClient from "@/client/EventClient.js";
export default {
  name: "EventLocations",
  props: {
    eventId: null,
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbBtn,
    mdbCol,
    mdbModal,
    EventLocation,
    LocationSelector,
    draggable,
  },
  mounted() {
    this.fetchEventLocations();
  },
  methods: {
    afterDrag() {
      //calculate new order indexes for event locations
      //this causes the update to 2 Location -components; (see Location-comp update() method)
      var index = 1;
      this.eventLocations.forEach((elocation) => {
        elocation.orderIndex = index++;
      });
    },
    async fetchEventLocations() {
      if (
        (this.eventLocations = await eventClient.getEventLocations(
          this.eventId
        ))
      ) {
        // store locations for runplan usage
        const locations = [];
        this.eventLocations.forEach((location) => {
          locations.push({
            text: location.name + " - " + location.detailedLocation,
            value: location.id,
          });
        });
        this.$store.commit("addRunplanLocations", locations);
      }
    },
    async addLocations(locations) {
      locations.forEach(async function (location) {
        const e = {
          locationId: location.id,
          name: location.name,
          detailedLocation: "",
          startDateTime: "",
          endDateTime: "",
          constructionDateTime: "",
          teardownDateTime: "",
          notes: "",
          status: "OPEN",
          orderIndex: 0,
        };
        var payload;
        if ((payload = await eventClient.addEventLocation(this.eventId, e))) {
          e.id = payload.id;
          this.eventLocations.push(e);
        }
      }, this);
      this.showLocationSelector = false;
    },
    onDelete(eventlocationid) {
      this.$confirmablePopup.show({
        text: this.$t("event.location.confirm_delete"),
        onConfirm: () => {
          return this.deleteLocation(eventlocationid);
        },
      });
    },
    async deleteLocation(eventlocationid) {
      if (eventClient.deleteEventLocation(this.eventId, eventlocationid)) {
        this.eventLocations = this.eventLocations.filter((location) => {
          return location.id != eventlocationid;
        });
      }
    },
  },
  data: () => ({
    eventLocations: [],
    allLocations: [],
    showLocationSelector: false,
    showLocations: false,
  }),
};
</script>

<style></style>
