<template>
  <mdb-container class="grey lighten-5">
    <mdb-row class="justify-content-end">
      <mdb-col col="3">
        <mdb-input v-model="search" bg :label="$t('location.search')" />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-end">
      <mdb-btn
        :disabled="selectedLocation === undefined"
        outline="elegant"
        @click="modifySelectedLocation()"
        >{{ $t("location.modify") }}</mdb-btn
      >
      <mdb-btn
        :disabled="selectedLocation === undefined"
        outline="elegant"
        @click="onDelete()"
        >{{ $t("location.delete") }}</mdb-btn
      >
      <mdb-btn outline="elegant" @click="addNewLocation()">{{
        $t("location.add_new")
      }}</mdb-btn>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-datatable-2
          v-model="locations"
          selectable
          @selected="selectLocation"
          :searching="{ value: search }"
          hover
          ref="datatable"
          selectColor="indigo lighten-3"
        />
      </mdb-col>
    </mdb-row>
    <EditLocationModal
      :location="localLocation"
      :show="showAddNewModal"
      :key="componentKey"
      v-on:modal-ok="savingLocation"
      v-if="localLocation != null"
    />
  </mdb-container>
</template>

<script>
import * as locationClient from "@/client/LocationClient.js";
import EditLocationModal from "@/components/locations/EditLocationModal.vue";
import Location from "@/model/Location.js";
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbInput,
  mdbDatatable2,
} from "mdbvue";

export default {
  name: "Locations",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbInput,
    EditLocationModal,
    mdbDatatable2,
  },
  methods: {
    async fetchLocations() {
      if ((this.localLocations = await locationClient.getLocations())) {
        this.locations = {
          columns: this.columns,
          rows: this.localLocations,
        };
      }
    },
    async savingLocation(location) {
      if (location.id) {
        await locationClient.updateLocation(location);
      } else {
        await locationClient.saveLocation(location);
      }
      this.locations = {
        columns: this.columns,
        rows: this.localLocations,
      };
      this.fetchLocations();
      this.$refs.datatable.selectRow(); // unselects all rows
    },
    showModal(location) {
      this.localLocation = location;
      this.componentKey += 1;
      this.showAddNewModal = true;
    },
    addNewLocation() {
      this.location = new Location();
      this.showModal(this.location);
    },
    selectLocation(val) {
      this.selectedLocation = val;
    },
    onDelete() {
      this.$confirmablePopup.show({
        text: this.$t("location.confirm_delete"),
        onConfirm: () => {
          this.deleteSelectedLocation();
        },
      });
    },
    async deleteSelectedLocation() {
      if (await locationClient.deleteLocation(this.selectedLocation.id)) {
        this.$refs.datatable.selectRow(); // unselects all rows
        this.fetchLocations();
      }
    },
    modifySelectedLocation() {
      this.showModal(this.selectedLocation);
    },
  },
  created: function () {
    this.fetchLocations();
  },
  data() {
    return {
      selectedLocation: undefined,
      localLocations: [],
      localLocation: null,
      componentKey: 0,
      showAddNewModal: false,
      search: "",
      columns: [
        {
          label: this.$t("location.name"),
          field: "name",
          sort: true,
        },
        {
          label: this.$t("location.streetAddress"),
          field: "streetAddress",
          sort: true,
        },
        {
          label: this.$t("location.postalcode"),
          field: "postalcode",
          sort: true,
        },
        {
          label: this.$t("location.city"),
          field: "city",
          sort: true,
        },
      ],
      locations: {
        rows: [],
        columns: this.localLocations,
      },
    };
  },
};
</script>

<style></style>
