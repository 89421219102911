<template>
  <div>
    <mdb-row>
      <mdb-col col="4">
        <mdb-input
          :label="$t('contact.firstname')"
          v-model="eventContact.firstname"
          disabled
        />
      </mdb-col>
      <mdb-col>
        <mdb-input
          :label="$t('contact.lastname')"
          v-model="eventContact.lastname"
          disabled
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <SelectSingle
          :options="userCategories"
          :label="$t('contact.category_select_label')"
          :text="'name'"
          :value="'id'"
          v-on:selection="userCategorySelected"
          :selectedValue="eventContact.categoryId"
          v-if="userCategories"
        />
      </mdb-col>
      <mdb-col>
        <SelectSingle
          :options="userRoles"
          :label="$t('contact.role_select_label')"
          :text="'name'"
          :value="'id'"
          :selectedValue="eventContact.roleId"
          v-on:selection="userRoleSelected"
          v-if="userRoles"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-btn
          class="float-right"
          size="sm"
          outline="elegant"
          @click="deleteContact()"
          >{{ $t("contact.delete") }}
        </mdb-btn>
        <mdb-btn
          class="float-right"
          size="sm"
          outline="elegant"
          @click="saveContact()"
          >{{ $t("contact.save") }}</mdb-btn
        >
        <EventHandler
          ref="eventHandler"
          :waitTime="5000"
          :text="$t('event.contact.saving')"
          :callback="saveContact"
        />
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { mdbRow, mdbCol, mdbInput, mdbBtn } from "mdbvue";
import * as eventClient from "@/client/EventClient.js";
import SelectSingle from "@/components/SelectSingle.vue";
import EventHandler from "@/components/EventHandler.vue";
export default {
  name: "EventContact",
  props: {
    eventContact: Object,
    eventId: String,
    userCategories: Array,
    userRoles: Array,
  },
  components: {
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
    SelectSingle,
    EventHandler,
  },
  mounted() {},
  methods: {
    async saveContact() {
      if (eventClient.addEventContact(this.eventId, this.eventContact)) {
        return true;
      }
    },
    async deleteContact() {
      if (
        await eventClient.deleteEventContact(this.eventId, this.eventContact.id)
      ) {
        this.$emit("onDelete", this.eventContact.id);
      }
    },
    userRoleSelected(val) {
      this.eventContact.roleId = val.id;
      this.onFieldChanged();
    },
    userCategorySelected(val) {
      this.eventContact.categoryId = val.id;
      this.onFieldChanged();
    },
    onFieldChanged() {
      if (this.$refs.eventHandler) {
        this.$refs.eventHandler.start();
      }
    },
  },
};
</script>

<style></style>
