import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const DEFAULT_LANG = "fi";

const locales = {
  en: require("./i18n/en.json"),
  fi: require("./i18n/fi.json"),
};
const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
});

export default i18n;
