import { getApi, putApi, deleteApi } from "@/client/AxiosClient.js";
import i18n from "@/lang/lang.js";
import store from "@/store/index.js";

export async function getNotes(eventid) {
  return await getApi({
    url: `${store.getters.getUser.email}/events/${eventid}/notes`,
    errorMsg: i18n.t("note.load_not_success"),
  });
}

export async function saveNote(eventid, body) {
  return await putApi({
    url: `${store.getters.getUser.email}/events/${eventid}/notes`,
    body: body,
    errorMsg: i18n.t("note.create_not_success"),
  });
}

export async function deleteNote(eventid, id) {
  return await deleteApi({
    url: `${store.getters.getUser.email}/events/${eventid}/notes/${id}`,
    errorMsg: i18n.t("note.delete_not_success"),
  });
}
