<template>
  <div>
    <HeaderBase :title="$t('location.locations')" />
    <Locations />
  </div>
</template>

<script>
import HeaderBase from "@/components/HeaderBase.vue";
import Locations from "@/components/locations/Locations.vue";
export default {
  name: "ContactsView",
  components: {
    HeaderBase,
    Locations,
  },
};
</script>

<style></style>
