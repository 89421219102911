<template>
  <mdb-container>
    <mdb-row class="justify-content-end">
      <div
        class="mr-2 mb-2"
        v-for="(eventPhase, index) in eventPhases"
        v-bind:index="index"
        v-bind:key="index"
      >
        <EventPhase
          v-on:selectedPhase="selectedPhase"
          :eventPhase="eventPhase"
        />
      </div>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow } from "mdbvue";
import EventPhase from "@/components/events/eventphases/EventPhase.vue";
import eventPhaseData from "@/assets/json/EventPhases.json";
export default {
  name: "EventPhases",
  components: {
    mdbContainer,
    mdbRow,
    EventPhase,
  },
  created: function () {},
  mounted() {
    this.resetPhaseColors("EventBasicInformation");
  },
  data: () => ({
    eventPhases: eventPhaseData.eventphases,
  }),
  methods: {
    resetPhaseColors(val) {
      this.eventPhases.forEach((element) => {
        element.id === val ? (element.active = 1) : (element.active = 0);
      });
    },
    selectedPhase(val) {
      this.resetPhaseColors(val);
      this.$emit("selectedPhase", val);
    },
  },
};
</script>

<style></style>
