<template>
  <div>
    <HeaderBase :title="$t('company.companies')" />
    <Companies />
  </div>
</template>

<script>
import HeaderBase from "@/components/HeaderBase.vue";
import Companies from "@/components/customers/Companies.vue";
export default {
  name: "CompaniesView",
  components: {
    HeaderBase,
    Companies,
  },
};
</script>

<style></style>
