<template>
  <div>
    <mdb-row>
      <mdb-col col="">
        <mdb-input
          :label="$t('user.firstname')"
          v-model="user.firstname"
          disabled
        />
      </mdb-col>
      <mdb-col col="">
        <mdb-input
          :label="$t('user.lastname')"
          v-model="user.lastname"
          disabled
        />
      </mdb-col>
      <mdb-col col="">
        <mdb-input
          :label="$t('user.phonenumber')"
          v-model="user.phonenumber"
          disabled
        />
      </mdb-col>
      <mdb-col col="">
        <mdb-select
          @getValue="roleSelected"
          v-model="eventUserRoleList"
          :visibleOptions="6"
          :label="$t('event.user.role')"
          :placeholder="$t('event.user.role')"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-btn
          class="float-right"
          size="sm"
          outline="elegant"
          @click="saveUser()"
          >{{ $t("user.save") }}</mdb-btn
        >
        <mdb-btn
          class="float-right"
          size="sm"
          outline="elegant"
          @click="deleteUser()"
          >{{ $t("user.delete") }}</mdb-btn
        >
        <EventHandler
          ref="eventHandler"
          :waitTime="5000"
          :text="$t('event.user.saving')"
          :callback="saveUser"
        />
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { mdbRow, mdbCol, mdbInput, mdbBtn, mdbSelect } from "mdbvue";
import * as eventClient from "@/client/EventClient.js";
import EventHandler from "@/components/EventHandler.vue";
export default {
  name: "EventUser",
  props: {
    user: Object,
    eventId: String,
  },
  components: {
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
    mdbSelect,
    EventHandler,
  },
  mounted() {
    const rolelist = this.$t("event.user.roleList");
    rolelist.forEach(function (option) {
      this.eventUserRoleList.push({
        text: option.text,
        value: option.value,
      });
    }, this);
    this.setSelectedRole();
  },
  methods: {
    roleSelected(val) {
      this.user.role = val;
      this.onFieldChanged();
    },
    async saveUser() {
      if (eventClient.addEventUsers(this.eventId, [this.user])) {
        return true;
      }
    },
    async deleteUser() {
      this.$emit("onDelete", this.user.id);
    },
    setSelectedRole() {
      if (!this.user || !this.user.id) {
        //this.eventUserRoleList[0].selected = true;
      } else {
        const usersRoleOnList = this.eventUserRoleList.find((role) => {
          return role.value == this.user.role;
        });
        if (usersRoleOnList) usersRoleOnList.selected = true;
      }
    },
    onFieldChanged() {
      if (this.$refs.eventHandler) {
        this.$refs.eventHandler.start();
      }
    },
  },
  data: () => ({
    eventUserRoleList: [],
  }),
};
</script>

<style></style>
