<template>
  <div>
    <HeaderBase
      :title="
        $t('event.event') +
        ': ' +
        (this.$store.getters.getEvent ? this.$store.getters.getEvent.name : '')
      "
    />
    <EventPhaseControl />
  </div>
</template>

<script>
import HeaderBase from "@/components/HeaderBase.vue";
import EventPhaseControl from "@/components/events/eventphases/EventPhaseControl.vue";
export default {
  name: "EventEditorView",
  components: {
    HeaderBase,
    EventPhaseControl,
  },
  created: function () {},
  mounted() {},
  data: () => ({}),
  methods: {},
};
</script>

<style></style>
