<template>
  <div>
    <HeaderBase :title="$t('user.users')" />
    <Users />
  </div>
</template>

<script>
import HeaderBase from "@/components/HeaderBase.vue";
import Users from "@/components/users/Users.vue";
export default {
  name: "UsersView",
  components: {
    HeaderBase,
    Users,
  },
};
</script>

<style></style>
