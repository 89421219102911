<template>
  <mdb-modal :show="showme" @close="showme = false">
    <mdb-container>
      <mdb-modal-header class="h3">{{ title }}</mdb-modal-header>
      <mdb-modal-body class="text-center h4">{{ text }}</mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn
          class="float-left"
          size="sm"
          outline="elegant"
          @click="confirm()"
          >{{ $t("common.popup.confirm") }}
        </mdb-btn>
        <mdb-btn
          class="float-right"
          size="sm"
          outline="elegant"
          @click="cancel()"
          >{{ $t("common.popup.cancel") }}</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-container>
  </mdb-modal>
</template>

<script>
import {
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbContainer,
  mdbBtn,
} from "mdbvue";
import { ConfirmablePopup } from "../plugins/popup.js";

export default {
  name: "ConfirmablePopupComp",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbContainer,
  },
  data: () => ({
    showme: false,
    title: "Confirm operation",
    text: "Do you copy?",
    onConfirm: {},
  }),
  methods: {
    show(params) {
      this.title = params.title
        ? params.title
        : this.$t("common.confirm_delete");
      this.text = params.text;
      this.onConfirm = params.onConfirm;
      this.showme = true;
    },
    cancel() {
      this.showme = false;
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === "function") {
        this.onConfirm();
        this.cancel();
      } else {
        this.cancel();
      }
    },
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    ConfirmablePopup.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
};
</script>
