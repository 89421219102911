<template>
  <modal
    name="note-edit-modal"
    :adaptive="true"
    :resizable="true"
    :scrollable="true"
    :clickToClose="false"
    width="80%"
    height="auto"
  >
    <mdb-modal-header class="text-center">
      <mdb-modal-title tag="h4" bold class="w-100">{{
        $t("note.information")
      }}</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="">
      <h4>{{ note.userId }}</h4>
      <div style="width: 50%; margin: auto">
        <DateTimeFromTo
          v-on:dateTime="setNoteDate"
          :dateTime="note.noteDate"
          :dateTitle="$t('note.date')"
        />
      </div>
      <vue-editor v-model="note.content" style="height: 400px" />
    </mdb-modal-body>
    <mdb-modal-footer class="mt-5">
      <mdb-btn @click="saveNote()" outline="elegant">
        {{ $t("note.save") }}
      </mdb-btn>
      <mdb-btn @click="closeModal()" outline="elegant">
        {{ $t("note.quit") }}
      </mdb-btn>
    </mdb-modal-footer>
  </modal>
</template>

<script>
import {
  mdbBtn,
  mdbModalHeader,
  mdbModalFooter,
  mdbModalTitle,
  mdbModalBody,
} from "mdbvue";
import DateTimeFromTo from "@/components/DateTimeFromTo.vue";
import { VueEditor } from "vue2-editor";
export default {
  name: "ModalExamplesPage",
  components: {
    mdbBtn,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    DateTimeFromTo,
    mdbModalTitle,
    VueEditor,
  },
  props: {
    note: Object,
  },
  methods: {
    setNoteDate(val) {
      this.note.noteDate = val;
    },
    saveNote() {
      this.$emit("modal-ok", this.note);
      this.$modal.hide("note-edit-modal");
    },
    closeModal() {
      this.$emit("modal-closed");
      this.$modal.hide("note-edit-modal");
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$modal.show("note-edit-modal");
  },
  computed: {},
};
</script>

<style>
.close {
  display: none;
}
</style>
