export default class Company {
  constructor(
    id = "",
    companyId = "",
    name = "",
    eInvoiceAddress = "",
    eInvoiceOperatorId = "",
    eInvoiceIntermediator = "",
    eInvoiceEmail = "",
    notes = ""
  ) {
    this.id = id;
    this.companyId = companyId;
    this.name = name;
    this.eInvoiceAddress = eInvoiceAddress;
    this.eInvoiceOperatorId = eInvoiceOperatorId;
    this.eInvoiceIntermediator = eInvoiceIntermediator;
    this.eInvoiceEmail = eInvoiceEmail;
    this.notes = notes;
  }
}
