<template>
  <mdb-card
    class="eventphasecard"
    :class="{ selectedcard: eventPhase.active === 1 }"
    @click.native="selectedPhase"
    style="cursor: pointer"
  >
    <mdb-card-body class="text-center pl-0 pr-0 pt-2 pb-1">
      <img
        :src="require(`@/assets/img/${eventPhase.image}`)"
        alt=""
        height="50"
      />
      <div class="align-middle">
        <mdb-card-title class="text-center">{{
          eventPhase.text
        }}</mdb-card-title>
      </div>
    </mdb-card-body>
  </mdb-card>
</template>

<script>
import { mdbCard, mdbCardBody, mdbCardTitle } from "mdbvue";
export default {
  name: "EventPhase",
  components: {
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
  },
  props: {
    eventPhase: Object,
  },
  created: function () {},
  mounted() {},
  data: () => ({}),
  methods: {
    selectedPhase() {
      this.$emit("selectedPhase", this.eventPhase.id);
    },
  },
};
</script>

<style scoped>
.eventphasecard {
  width: 170px;
  height: 120px;
}
.selectedcard {
  background-color: rgba(117, 117, 117, 0.6);
}
</style>
