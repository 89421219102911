<template>
  <iframe
    width="95%"
    height="800"
    frameborder="0"
    scrolling="no"
    :src="excel"
  ></iframe>
</template>

<script>
export default {
  name: "ExcelFrame",
  components: {},
  props: {
    excel: String,
  },
  created: function () {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
