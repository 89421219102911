<template>
  <mdb-container>
    <mdb-row>
      <mdb-col>EventPreview</mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol } from "mdbvue";
export default {
  name: "EventPreview",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
  },
  created: function () {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
