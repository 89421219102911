import { getApi, putApi, postApi, deleteApi } from "@/client/AxiosClient.js";
import i18n from "@/lang/lang.js";
import store from "@/store/index.js";

export async function getLocations() {
  return await getApi({
    url: `${store.getters.getUser.email}/locations`,
    errorMsg: i18n.t("location.loads_not_success"),
  });
}

export async function getLocation(locationid) {
  return await getApi({
    url: `${store.getters.getUser.email}/locations/${locationid}`,
    errorMsg: i18n.t("location.load_not_success"),
  });
}

export async function saveLocation(body) {
  return await postApi({
    url: `${store.getters.getUser.email}/locations`,
    body,
    errorMsg: i18n.t("location.create_not_success"),
  });
}

export async function updateLocation(body) {
  return await putApi({
    url: `${store.getters.getUser.email}/locations/${body.id}`,
    body,
    errorMsg: i18n.t("location.update_not_success"),
  });
}

export async function deleteLocation(locationid) {
  return await deleteApi({
    url: `${store.getters.getUser.email}/locations/${locationid}`,
    errorMsg: i18n.t("location.delete_not_success"),
  });
}
