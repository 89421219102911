<template>
  <mdb-container class="grey lighten-5" v-if="event">
    <mdb-row>
      <mdb-col>
        <mdb-input
          :label="$t('event.name')"
          v-model="event.name"
          :invalidFeedback="this.$t('event.give_a_name')"
          @change="checkName"
          :customValidation="customValidationOn"
          :isValid="nameIsValid"
          validFeedback=" "
        />
      </mdb-col>
      <mdb-col>
        <mdb-select
          @getValue="statusSelected"
          v-model="eventStatusList"
          :visibleOptions="6"
          :label="$t('event.status_label')"
          :placeholder="$t('event.status_label')"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-input
          :label="$t('event.logicalId')"
          v-model="event.logicalId"
          @blur="checkLogicalId"
          :customValidation="customValidationOn"
          :isValid="logicalIdIsValid"
          validFeedback=" "
          :invalidFeedback="this.$t('event.give_a_unique_logicalid')"
        />
      </mdb-col>
      <mdb-col>
        <mdb-input
          :label="$t('event.numOfGuests')"
          v-model="event.numOfGuests"
          type="number"
          :min="0"
          @change="onFieldChanged"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <DateTimeFromTo
          v-on:dateTime="setStartDateTime"
          :dateTitle="$t('event.start')"
          :dateTime="event.startDateTime"
        />
      </mdb-col>
      <mdb-col>
        <DateTimeFromTo
          v-on:dateTime="setEndDateTime"
          :dateTitle="$t('event.end')"
          :dateTime="event.endDateTime"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-input
          :label="$t('event.description')"
          type="textarea"
          v-model="event.description"
          @change="onFieldChanged"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-input
          :label="$t('event.notes')"
          type="textarea"
          v-model="event.notes"
          @change="onFieldChanged"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-btn
          class="float-right"
          size="sm"
          outline="elegant"
          @click="createEvent()"
          >{{ $t("event.save") }}</mdb-btn
        >
        <EventHandler
          ref="eventHandler"
          :waitTime="5000"
          :text="$t('event.saving')"
          :callback="createEvent"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <EventLocations :eventId="event.id" />
    </mdb-row>
    <mdb-row>
      <EventContacts :eventId="event.id" />
    </mdb-row>
    <mdb-row>
      <EventUsers :eventId="event.id" />
    </mdb-row>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbBtn,
  mdbSelect,
} from "mdbvue";
import DateTimeFromTo from "@/components/DateTimeFromTo.vue";
import EventLocations from "@/components/events/EventLocations.vue";
import EventContacts from "@/components/events/EventContacts.vue";
import EventUsers from "@/components/events/EventUsers.vue";
import * as eventClient from "@/client/EventClient.js";
import EventHandler from "@/components/EventHandler.vue";
export default {
  name: "EventBasicInformation",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
    mdbSelect,
    DateTimeFromTo,
    EventLocations,
    EventContacts,
    EventUsers,
    EventHandler,
  },
  methods: {
    async createEvent() {
      if (!this.nameIsValid || !this.logicalIdIsValid) return true;
      var payload;
      if ((payload = await eventClient.saveEvent(this.event))) {
        if (!this.event.id) {
          this.event.id = payload.id;
        }
        this.$store.commit("updateEvent", this.event);
        return true;
      }
    },
    setStartDateTime(dateTime) {
      this.event.startDateTime = dateTime;
      this.onFieldChanged();
    },
    setEndDateTime(dateTime) {
      this.event.endDateTime = dateTime;
      this.onFieldChanged();
    },
    statusSelected(val) {
      this.event.status = val;
      this.onFieldChanged();
    },
    async fetchEvent(id) {
      if ((this.event = await eventClient.getEvent(id))) {
        this.$store.commit("updateEvent", this.event);
        this.originalLogicalId = this.event.logicalId;
        // as default, all event values are supposed to be valid
        this.customValidationOn = true;
        this.nameIsValid = true;
        this.logicalIdIsValid = true;
        this.eventStatusList.filter((status) => {
          return status.value == this.event.status;
        })[0].selected = true;
      } else {
        // user may not have access to the event anymore, reset the store and go home
        this.$store.commit("updateEvent", undefined);
        this.$router.push({
          name: "Home",
        });
      }
    },
    checkLogicalId() {
      this.logicalIdIsValid = true;
      if (!this.event.logicalId || this.event.logicalId == "") {
        this.logicalIdIsValid = false;
      } else if (this.event.logicalId != this.originalLogicalId) {
        this.checkLogicalIdFromBackend();
      }
    },
    checkName() {
      this.nameIsValid = true;
      if (!this.event.name || this.event.name == "") {
        this.nameIsValid = false;
      } else {
        this.onFieldChanged();
      }
    },
    async checkLogicalIdFromBackend() {
      this.logicalIdIsValid = !(await eventClient.checkIfLogicalIdIsUsed(
        this.event.logicalId
      ));
      if (this.logicalIdIsValid) this.onFieldChanged();
    },
    onFieldChanged() {
      if (this.$refs.eventHandler) {
        this.$refs.eventHandler.start();
      }
    },
  },
  data: () => ({
    dateTimeLocal: undefined,
    event: undefined,
    logicalIdIsValid: false,
    originalLogicalId: null,
    nameIsValid: false,
    customValidationOn: true,
    eventStatusList: [],
  }),
  mounted() {
    this.dateTimeLocal = this.dateTime;
    const eventid = this.$route.params.eventId;

    this.$t("event.statuslist").forEach(function (option) {
      this.eventStatusList.push({
        text: option.text,
        value: option.value,
      });
    }, this);

    if (eventid) {
      this.fetchEvent(eventid);
    } else {
      this.event = {
        name: "",
        description: "",
        startDateTime: "",
        endDateTime: "",
        targetAudience: "",
        numOfGuests: "",
        notes: "",
        status: this.eventStatusList[0].value,
      };
      this.$store.commit("updateEvent", this.event);
      this.eventStatusList[0].selected = true;
    }
  },
  beforeDestroy() {
    this.$store.commit("updateEvent", null);
  },
};
</script>

<style></style>
