<template>
  <mdb-modal :show="showme" @close="showme = false">
    <mdb-container>
      <mdb-modal-header class="h3">{{ title }}</mdb-modal-header>
      <mdb-modal-body class="text-center h4">{{ text }}</mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn
          class="float-right"
          size="sm"
          outline="elegant"
          @click="cancel()"
          >{{ $t("common.popup.confirm") }}</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-container>
  </mdb-modal>
</template>

<script>
import {
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbContainer,
  mdbBtn,
} from "mdbvue";
import { InfoPopup } from "../plugins/popup.js";

export default {
  name: "InfoPopupComp",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbContainer,
  },
  data: () => ({
    showme: false,
    title: "",
    text: "",
  }),
  methods: {
    show(params) {
      this.title = params.title ? params.title : this.$t("common.info_header");
      this.text = params.text;
      this.showme = true;
    },
    cancel() {
      this.showme = false;
    },
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    InfoPopup.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
};
</script>
