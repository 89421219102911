<template>
  <mdb-container class="grey lighten-5">
    <mdb-row class="justify-content-end">
      <mdb-col col="3">
        <mdb-input v-model="search" bg :label="$t('company.search')" />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-end">
      <DataTableButtons
        :exportText="$t('company.export')"
        :addText="$t('company.add')"
        :modifyText="$t('company.modify')"
        :deleteText="$t('company.delete')"
        :refreshText="$t('company.refresh')"
        :disabled="selectedCompany === undefined"
        v-on:addEvent="addingCompany"
        v-on:modifyEvent="modifyingCompany"
        v-on:deleteEvent="onDelete"
        v-on:refreshEvent="refreshingCompanies"
        v-on:exportEvent="exportingCompanies"
      />
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-datatable-2
          v-if="companies.rows.length > 0"
          v-model="companies"
          selectable
          @selected="selectingCompany"
          :searching="{ value: search }"
          hover
          selectColor="indigo lighten-3"
        />
      </mdb-col>
    </mdb-row>
    <EditCompanyModal
      :company="localCompany"
      :show="showAddNewModal"
      :key="componentKey"
      v-on:modal-ok="savingCompany"
      v-on:modal-closed="closingCompany"
      v-if="localCompany"
    />
  </mdb-container>
</template>

<script>
import * as companyClient from "@/client/CompanyClient.js";
import Company from "@/model/Company.js";
import DataTableButtons from "@/components/DataTableButtons.vue";
import EditCompanyModal from "@/components/customers/EditCompanyModal.vue";
import { mdbContainer, mdbRow, mdbCol, mdbInput, mdbDatatable2 } from "mdbvue";
export default {
  name: "Companies",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbInput,
    EditCompanyModal,
    mdbDatatable2,
    DataTableButtons,
  },
  methods: {
    async fetchingCompanies() {
      this.companies = {
        columns: this.columns,
        rows: [],
      };
      if ((this.localCompanies = await companyClient.getCompanies())) {
        this.companies = {
          columns: this.columns,
          rows: this.localCompanies,
        };
      }
    },
    async savingCompany(val) {
      if (!val.id) {
        if (await companyClient.saveCompany(val)) {
          this.notifySuccess(this.$t("company.create_success"));
          this.fetchingCompanies();
        }
      } else {
        if (await companyClient.updateCompany(val)) {
          this.notifySuccess(this.$t("company.update_success"));
          this.fetchingCompanies();
        }
      }
    },
    showModal(company) {
      this.localCompany = company;
      this.componentKey += 1;
      this.showAddNewModal = true;
    },
    closingCompany() {
      this.fetchingCompanies();
    },
    refreshingCompanies() {
      this.fetchingCompanies();
    },
    exportingCompanies() {
      this.createCSV(this.localCompanies, "Companies.csv");
    },
    addingCompany() {
      this.company = new Company();
      this.showModal(this.company);
    },
    selectingCompany(val) {
      this.selectedCompany = val;
    },
    onDelete() {
      this.$confirmablePopup.show({
        text: this.$t("company.confirm_delete"),
        onConfirm: () => {
          this.deletingCompany();
        },
      });
    },
    async deletingCompany() {
      if (await companyClient.deleteCompany(this.selectedCompany.id)) {
        this.notifySuccess(this.$t("company.delete_success"));
        this.fetchingCompanies();
      }
    },
    modifyingCompany() {
      this.showModal(this.selectedCompany);
    },
  },
  created: function () {
    this.fetchingCompanies();
  },
  data() {
    return {
      selectedCompany: undefined,
      localCompanies: [],
      localCompany: null,
      componentKey: 0,
      showAddNewModal: false,
      search: "",
      columns: [
        {
          label: this.$t("company.companyid"),
          field: "companyId",
          sort: true,
        },
        {
          label: this.$t("company.name"),
          field: "name",
          sort: true,
        },
        {
          label: this.$t("company.einvoiceaddress"),
          field: "eInvoiceAddress",
          sort: true,
        },
        {
          label: this.$t("company.einvoiceoperatorid"),
          field: "eInvoiceOperatorId",
          sort: true,
        },
        {
          label: this.$t("company.einvoiceintermediator"),
          field: "eInvoiceIntermediator",
          sort: true,
        },
        {
          label: this.$t("company.einvoiceemail"),
          field: "eInvoiceEmail",
          sort: true,
        },
        {
          label: this.$t("company.notes"),
          field: "notes",
          sort: true,
        },
      ],
      companies: {
        rows: [],
        columns: this.localCompanies,
      },
    };
  },
};
</script>

<style></style>
