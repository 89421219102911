<template>
  <div v-if="params !== undefined">
    <mdb-input
      :type="params.inputType"
      :id="params.column.colId + params.rowIndex"
      v-model="params.value"
      @change="handleChange"
      @blur="handleBlur"
      v-on:keyup.enter.native="handleEnter"
    />
  </div>
</template>

<script>
import { mdbInput } from "mdbvue";
export default {
  name: "AgInput",
  components: {
    mdbInput,
  },
  data() {
    return {
      localTime: String,
    };
  },
  created: function () {
    this.localTime = this.params.value;
  },
  methods: {
    handleChange(val) {
      this.localTime = val;
    },
    handleEnter() {
      this.params.setValue(this.localTime);
    },
    handleBlur() {
      this.handleEnter();
    },
  },
};
</script>

<style scoped>
.md-form {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
