<template>
  <mdb-container>
    <mdb-row
      v-for="(event, index) in events"
      v-bind:index="index"
      v-bind:key="index"
    >
      <Event :event="event" @onDelete="onDelete" />
    </mdb-row>
    <mdb-row v-if="events.length == 0">{{ $t("event.no_events") }} </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow } from "mdbvue";
import Event from "@/components/events/Event.vue";
import * as eventClient from "@/client/EventClient.js";
export default {
  name: "Events",
  components: {
    mdbContainer,
    mdbRow,
    Event,
  },
  mounted() {
    this.fetchEvents();
  },
  methods: {
    async fetchEvents() {
      this.events = await eventClient.getEvents();
    },
    onDelete(eventid) {
      this.$confirmablePopup.show({
        text: this.$t("event.confirm_delete"),
        onConfirm: () => {
          return this.eventDeleted(eventid);
        },
      });
    },
    async eventDeleted(eventid) {
      if (await eventClient.deleteEvent(eventid)) {
        this.events = this.events.filter((event) => {
          return event.id != eventid;
        });
      }
    },
  },
  data: () => ({
    events: [],
  }),
};
</script>

<style></style>
