<template>
  <div>
    <mdb-row>
      <mdb-col col="4">
        <mdb-input
          :label="$t('location.name')"
          v-model="location.name"
          disabled
        />
      </mdb-col>
      <mdb-col col="5">
        <mdb-input
          :label="$t('event.location.detailedLocation')"
          v-model="location.detailedLocation"
          @blur="onFieldChanged"
        />
      </mdb-col>
      <mdb-col col="3">
        <mdb-select
          @getValue="statusSelected"
          v-model="eventLocationStatusList"
          :visibleOptions="6"
          :label="$t('event.location.status')"
          :placeholder="$t('event.location.status')"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col col="3">
        <DateTimeFromTo
          v-on:dateTime="setStartDateTime"
          :dateTitle="$t('event.location.startDateTime')"
          :dateTime="location.startDateTime"
        />
      </mdb-col>
      <mdb-col col="3">
        <DateTimeFromTo
          v-on:dateTime="setEndDateTime"
          :dateTitle="$t('event.location.endDateTime')"
          :dateTime="location.endDateTime"
        />
      </mdb-col>
      <mdb-col col="3">
        <DateTimeFromTo
          v-on:dateTime="setConstructionDateTime"
          :dateTitle="$t('event.location.constructionDateTime')"
          :dateTime="location.constructionDateTime"
        />
      </mdb-col>
      <mdb-col col="3">
        <DateTimeFromTo
          v-on:dateTime="setTeardDownDateTime"
          :dateTitle="$t('event.location.teardownDateTime')"
          :dateTime="location.teardownDateTime"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-input
          :label="$t('event.location.notes')"
          type="textarea"
          v-model="location.notes"
          @blur="onFieldChanged"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-btn
          size="sm"
          outline="elegant"
          @click="deleteLocation()"
          class="float-right"
          >{{ $t("location.delete") }}</mdb-btn
        >
        <mdb-btn
          size="sm"
          outline="elegant"
          @click="saveLocation()"
          class="float-right"
          >{{ $t("location.save") }}</mdb-btn
        >
        <EventHandler
          ref="eventHandler"
          :waitTime="5000"
          :text="$t('event.location.saving')"
          :callback="saveLocation"
        />
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { mdbRow, mdbCol, mdbInput, mdbBtn, mdbSelect } from "mdbvue";
import * as eventClient from "@/client/EventClient.js";
import DateTimeFromTo from "@/components/DateTimeFromTo.vue";
import EventHandler from "@/components/EventHandler.vue";
export default {
  name: "EventLocation",
  props: {
    location: Object,
    eventId: String,
  },
  components: {
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
    mdbSelect,
    DateTimeFromTo,
    EventHandler,
  },
  mounted() {
    const statuslist = this.$t("event.location.statuslist");
    statuslist.forEach(function (option) {
      this.eventLocationStatusList.push({
        text: option.text,
        value: option.value,
      });
    }, this);
    this.setSelectedStatus();
    this.originalOrderIndex = this.location.orderIndex;
  },
  updated() {
    if (this.originalOrderIndex != this.location.orderIndex) {
      // user dragged the order of event locations to a new order
      // save location
      this.onFieldChanged();
      this.originalOrderIndex = this.location.orderIndex;
    }
  },
  methods: {
    setStartDateTime(dateTime) {
      this.location.startDateTime = dateTime;
      this.onFieldChanged();
    },
    setEndDateTime(dateTime) {
      this.location.endDateTime = dateTime;
      this.onFieldChanged();
    },
    setConstructionDateTime(dateTime) {
      this.location.constructionDateTime = dateTime;
      this.onFieldChanged();
    },
    setTeardDownDateTime(dateTime) {
      this.location.teardownDateTime = dateTime;
      this.onFieldChanged();
    },
    statusSelected(val) {
      this.location.status = val;
      this.onFieldChanged();
    },
    async saveLocation() {
      if (eventClient.addEventLocation(this.eventId, this.location)) {
        return true;
      }
    },
    async deleteLocation() {
      this.$emit("onDelete", this.location.id);
    },
    setSelectedStatus() {
      if (!this.location || !this.location.id) {
        this.eventLocationStatusList[0].selected = true;
      } else {
        this.eventLocationStatusList.find((status) => {
          return status.value == this.location.status;
        }).selected = true;
      }
    },
    onFieldChanged() {
      if (this.$refs.eventHandler) {
        this.$refs.eventHandler.start();
      }
    },
  },
  data: () => ({
    eventLocationStatusList: [],
    originalOrderIndex: 0,
  }),
};
</script>

<style></style>
