<template>
  <div>
    <mdb-autocomplete
      v-model="selection"
      :data="searchResults"
      :label="$t('googleservice.label_googleplace')"
      @clear="clearSearch"
      @search="handleSearch"
    />
  </div>
</template>

<script>
import { mdbAutocomplete } from "mdbvue";
export default {
  name: "GooglePlacesAutoCompleteJS",
  components: {
    mdbAutocomplete,
  },
  props: {
    searchLabel: String,
  },
  data: () => ({
    location: "",
    selection: null,
    searchResults: [],
    autoCompleteService: null,
    placesService: null,
    placesServiceRequest: {
      placeId: "",
      fields: [
        "name",
        "formatted_address",
        "address_component",
        "website",
        "international_phone_number",
        "geometry",
      ],
    },
  }),
  created: function () {
    this.mapsInit();
  },
  methods: {
    handleSearch(val) {
      this.location = val;
      console.log(val);
    },
    clearSearch() {
      this.$emit("place-result", null);
    },
    mapsInit() {
      this.autoCompleteService =
        new window.google.maps.places.AutocompleteService();
      this.placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    },
    findAddressComponents(components, component) {
      var comp = "";
      components.forEach((element) => {
        if (element.types.includes(component))
          try {
            if (element.long_name !== null) {
              comp = element.long_name;
            } else comp = element.short_name;
          } catch (error) {
            comp = "";
          }
      });
      return comp;
    },
    fillFieldsFromRequest(place, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        this.$emit("place-result", {
          name: place.name,
          streetaddress:
            this.findAddressComponents(place.address_components, "route") +
            " " +
            this.findAddressComponents(
              place.address_components,
              "street_number"
            ),
          postalcode: this.findAddressComponents(
            place.address_components,
            "postal_code"
          ),
          city: this.findAddressComponents(
            place.address_components,
            "locality"
          ),
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
      console.log(place, status);
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }
      this.searchResults = predictions.map(
        (prediction) => prediction.description + "(" + prediction.place_id + ")"
      );
    },
  },
  watch: {
    selection(newValue) {
      if (newValue) {
        this.placesServiceRequest.placeId = newValue.substring(
          newValue.lastIndexOf("(") + 1,
          newValue.lastIndexOf(")")
        );
        this.placesService.getDetails(
          this.placesServiceRequest,
          this.fillFieldsFromRequest
        );
        console.log(newValue);
      }
    },
    location(newValue) {
      if (newValue) {
        this.autoCompleteService.getPlacePredictions(
          {
            input: this.location,
            //types: ["establishment"],
            componentRestrictions: { country: ["fi"] },
          },
          this.displaySuggestions
        );
      }
    },
  },
};
</script>

<style></style>
