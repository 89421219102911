<template>
  <div class="d-flex align-items-center">
    <span
      v-if="active"
      class="font-italic"
      :class="[animated, { fadeIn: active }]"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "SavingLabel",
  props: { text: String },
  components: {},
  methods: {
    saving() {
      this.active = true;
      setTimeout(() => {
        this.active = false;
      }, 1000);
    },
  },
  data: () => ({
    active: false,
  }),
};
</script>

<style></style>
