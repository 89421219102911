<template>
  <mdb-container>
    <mdb-row>
      <mdb-col md="8">
        <LoginPhone />
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol } from "mdbvue";
import LoginPhone from "@/components/logins/LoginPhone.vue";

export default {
  name: "Login",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    LoginPhone,
  },
};
</script>
