<template>
  <mdb-row>
    <mdb-col>
      <mdb-input
        :label="dateTitle"
        type="date"
        v-model="dateTimeLocal.date"
        @change="changeValue"
      />
    </mdb-col>
    <mdb-col>
      <mdb-input
        :label="timeTitle"
        type="time"
        v-model="dateTimeLocal.time"
        @change="changeValue"
      />
    </mdb-col>
  </mdb-row>
</template>

<script>
import { mdbRow, mdbCol, mdbInput } from "mdbvue";
export default {
  name: "DateTimeFromTo",
  props: {
    dateTitle: {
      type: String,
      default: undefined,
    },
    timeTitle: {
      type: String,
      default: undefined,
    },
    dateTime: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: undefined,
    },
  },
  components: {
    mdbRow,
    mdbCol,
    mdbInput,
  },
  methods: {
    changeValue() {
      const newval =
        (this.dateTimeLocal.date ? this.dateTimeLocal.date + "T" : "") +
        (this.dateTimeLocal.time ? this.dateTimeLocal.time : "00:00");
      if (newval.length == 16) {
        this.$emit("dateTime", newval);
      } else {
        // datetime is not yet complete
        this.dateTimeLocal = {
          date: null,
          time: null,
        };
        this.$emit("dateTime", null);
      }
    },
  },
  data() {
    return {
      dateTimeLocal: {},
    };
  },
  mounted() {
    if (this.dateTime) {
      this.dateTimeLocal = {
        date: this.dateTime.toString().substr(0, 10),
        time: this.dateTime.toString().substr(11),
      };
    } else {
      this.dateTimeLocal = {
        date: null,
        time: null,
      };
    }
  },
};
</script>

<style></style>
