<template>
  <mdb-container fluid>
    <mdb-row class="bg-white">
      <mdb-btn outline="elegant" @click="addRow"
        >{{ $t("runplan.addrow") }}
      </mdb-btn>
      <mdb-btn outline="elegant" @click="saveRP"
        >{{ $t("runplan.save") }}
      </mdb-btn>
      <mdb-btn outline="elegant" @click="undoRunplan"
        ><mdb-icon icon="undo" class="mr-1"
      /></mdb-btn>
      <mdb-btn outline="elegant" @click="redoRunplan"
        ><mdb-icon icon="redo" class="mr-1"
      /></mdb-btn>
      <mdb-btn outline="elegant" @click="gridApi.deselectAll()"
        >{{ $t("runplan.remove_selections") }}
      </mdb-btn>
      <mdb-btn
        :disabled="selectedRows.length == 0"
        outline="elegant"
        @click="adjustTiming"
        >{{ $t("runplan.calculate_timing") }}
      </mdb-btn>
      <mdb-btn outline="elegant" @click="orderNatively"
        >{{ $t("runplan.native_order") }}
      </mdb-btn>
      <div
        class="mt-2 mb-2 ml-1 z-depth-1 border d-flex align-items-center justify-content-center"
        ref="eBin"
        style="width: 13rem"
      >
        <p class="m-0">Raahaa poistaaksesi rivi</p>
      </div>
      <SavingLabel ref="savingLabel" :text="$t('runplan.saving')" />
      <mdb-btn
        class="ml-auto"
        style="margin-right: 70px !important"
        outline="elegant"
        @click="refreshRunplan"
        >{{ $t("runplan.refresh") }}
      </mdb-btn>
    </mdb-row>
    <mdb-row style="overflow: hidden">
      <ag-grid-vue
        v-if="columnsData.length > 0"
        style="width: 100%; height: 600px"
        class="ag-theme-alpine"
        :undoRedoCellEditing="true"
        :columnDefs="columnsData"
        :rowData="rowsData"
        :rowDragManaged="true"
        :animateRows="true"
        :rowDragMultiRow="true"
        :rowSelection="rowSelection"
        @row-selected="onRowSelected"
        @grid-ready="onGridReady"
        @cell-value-changed="onCellChanged"
      >
      </ag-grid-vue>
    </mdb-row>
  </mdb-container>
</template>

<script>
import * as runplanClient from "@/client/RunplanClient.js";
import Runplan from "@/model/Runplan.js";
import { mdbContainer, mdbRow, mdbBtn, mdbIcon } from "mdbvue";
import { AgGridVue } from "ag-grid-vue";
import AgInput from "@/components/AgInput";
import AgSelect from "@/components/AgSelect";
import SavingLabel from "@/components/SavingLabel.vue";
export default {
  name: "EventRunPlan",
  components: {
    mdbContainer,
    mdbRow,
    mdbBtn,
    mdbIcon,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgInput,
    // eslint-disable-next-line vue/no-unused-components
    AgSelect,
    SavingLabel,
  },
  created: function () {
    this.eventId = this.$route.params.eventId;
    this.rowSelection = "multiple";
    this.createGroups();
    this.fetchingRunplan(this.eventId);
  },
  beforeUpdate() {
    console.log("beforeUpdate");
  },
  updated() {
    console.log("updated");
  },
  mounted() {},
  beforeMount() {
    this.columnsData = [
      {
        width: 200,
        rowDrag: true,
        headerName: this.$t("runplan.date"),
        field: "date",
        sortable: true,
        filter: true,
        resizable: true,
        lockVisible: true,
        cellRenderer: "AgInput",
        cellRendererParams: {
          inputType: "date",
        },
      },
      {
        width: 120,
        field: "group",
        headerName: this.$t("runplan.group"),
        sortable: true,
        filter: true,
        resizable: true,
        lockVisible: true,
        editable: true,
        cellEditor: "AgSelect",
        cellEditorPopup: true,
        wrapText: true,
        autoHeight: true,
        cellEditorParams: {
          selectOptions: this.groups,
          selectPlaceHolder: "Valitse",
          selectOption: "group",
        },
      },
      {
        width: 120,
        field: "from",
        headerName: this.$t("runplan.from"),
        sortable: true,
        filter: true,
        resizable: true,
        lockVisible: true,
        cellEditor: "AgInput",
        cellEditorParams: {
          inputType: "time",
        },
      },
      {
        width: 100,
        field: "duration",
        headerName: this.$t("runplan.duration"),
        sortable: true,
        filter: true,
        resizable: true,
        lockVisible: true,
        editable: true,
      },
      {
        width: 120,
        field: "to",
        headerName: this.$t("runplan.to"),
        sortable: true,
        filter: true,
        resizable: true,
        lockVisible: true,
        cellRenderer: "AgInput",
        cellRendererParams: {
          inputType: "time",
        },
      },
      {
        width: 120,
        field: "location",
        headerName: this.$t("runplan.location"),
        sortable: true,
        filter: true,
        resizable: true,
        lockVisible: true,
        editable: true,
        cellEditor: "AgSelect",
        cellEditorPopup: true,
        wrapText: true,
        autoHeight: true,
        cellEditorParams: {
          selectOptions: [],
          selectPlaceHolder: "Valitse",
          selectOption: "location",
        },
      },
      {
        width: 150,
        field: "notes",
        headerName: this.$t("runplan.notes"),
        sortable: true,
        filter: true,
        editable: true,
        lockVisible: true,
        resizable: true,
        cellEditorPopup: true,
        wrapText: true,
        autoHeight: true,
        cellEditor: "agLargeTextCellEditor",
      },
      {
        width: 150,
        field: "media",
        headerName: this.$t("runplan.media"),
        sortable: true,
        filter: true,
        editable: true,
        lockVisible: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
      },
      {
        width: 150,
        field: "light",
        headerName: this.$t("runplan.light"),
        sortable: true,
        filter: true,
        editable: true,
        lockVisible: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
      },
      {
        width: 150,
        field: "sound",
        headerName: this.$t("runplan.sound"),
        sortable: true,
        filter: true,
        editable: true,
        lockVisible: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
      },
      {
        width: 150,
        field: "otsikko",
        headerName: this.$t("runplan.title"),
        sortable: true,
        filter: true,
        editable: true,
        lockVisible: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
      },
      {
        width: 120,
        field: "responsible",
        headerName: this.$t("runplan.responsible"),
        sortable: true,
        filter: true,
        resizable: true,
        editable: true,
        lockVisible: true,
        cellEditor: "AgSelect",
        cellEditorPopup: true,
        cellEditorParams: {
          selectOptions: [],
          selectPlaceHolder: "Valitse",
          selectOption: "responsible",
        },
        wrapText: true,
        autoHeight: true,
      },
      {
        width: 140,
        field: "appvisibility",
        headerName: this.$t("runplan.appvisibility"),
        sortable: true,
        filter: true,
        resizable: true,
        lockVisible: true,
        cellRenderer: "AgInput",
        cellRendererParams: {
          inputType: "checkbox",
        },
        wrapText: true,
        autoHeight: true,
      },
    ];
    //console.log(JSON.stringify(this.columnsData));
  },
  data() {
    return {
      contentDirty: false,
      columnsData: [],
      rowsData: [],
      locations: [],
      contacts: [],
      groups: [],
      gridApi: null,
      gridColumnApi: null,
      selectedRows: [],
      runplan: null,
    };
  },
  methods: {
    onRowSelected(event) {
      this.selectedRows = this.gridApi.getSelectedNodes();
      console.log(event);
    },
    onCellChanged(val) {
      this.checkAutomatics(val);
      this.saveRP();
      this.contentDirty = true;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      //this.setSavingInterval();
      this.addBinZone();
    },
    undoRunplan() {
      this.gridApi.undoCellEditing();
      this.saveRP();
      this.contentDirty = true;
    },
    redoRunplan() {
      this.gridApi.redoCellEditing();
      this.saveRP();
      this.contentDirty = true;
    },
    orderNatively() {
      this.rowsData.sort(
        (a, b) => a.date.localeCompare(b.date) || a.from.localeCompare(b.from)
      );
      this.gridApi.refreshCells();
      setTimeout(
        function () {
          this.saveRP();
        }.bind(this),
        100
      );
      this.contentDirty = true;
    },
    returnFirstFreeGroup() {
      var freeGroup = 0;
      this.groups.every((group) => {
        if (
          this.rowsData.find((element) => element.group === group.value) ===
            undefined &&
          group.value !== 0
        ) {
          freeGroup = group.value;
          return false;
        }
        return true;
      });
      return freeGroup;
    },
    addGroupForSelectedRows() {
      const group = this.returnFirstFreeGroup();
      for (let i = 0; i < this.selectedRows.length; i++) {
        this.selectedRows[i].data.group = group;
      }
    },
    adjustTiming() {
      for (let i = 1; i < this.selectedRows.length; i++) {
        this.selectedRows[i].data.from = this.selectedRows[i - 1].data.to;
        this.selectedRows[i].data.to = this.selectedRows[i].data.to =
          this.$moment(
            this.selectedRows[i].data.date +
              " " +
              this.selectedRows[i].data.from
          )
            .add(this.selectedRows[i].data.duration, "minutes")
            .format("HH:mm");
      }
      this.addGroupForSelectedRows();
      this.gridApi.refreshCells();
      setTimeout(
        function () {
          this.saveRP();
        }.bind(this),
        100
      );
    },
    binDrop(row) {
      this.gridApi.applyTransaction({ remove: [row.data] });
      this.saveRP();
      this.contentDirty = true;
    },
    addBinZone() {
      const dropZone = {
        getContainer: () => this.$refs.eBin,
        onDragEnter: () => {
          this.$refs.eBin.style.color = "red";
          this.$refs.eBin.style.fontSize = "110%";
        },
        onDragLeave: () => {
          this.$refs.eBin.style.color = "black";
          this.$refs.eBin.style.fontSize = "100%";
        },
        onDragStop: (params) => {
          this.binDrop(params.node);
          this.$refs.eBin.style.color = "black";
          this.$refs.eBin.style.fontSize = "100%";
        },
      };
      this.gridApi.addRowDropZone(dropZone);
    },
    setSavingInterval() {
      var _self = this;
      setInterval(() => {
        if (_self.contentDirty) {
          _self.saveRP();
          console.log("AUTOSAVE");
        }
      }, 10000);
    },
    addRow() {
      this.runplan = new Runplan();
      if (this.selectedRows.length > 0) {
        this.runplan.date = this.selectedRows.slice(-1)[0].data.date;
        this.runplan.from = this.selectedRows.slice(-1)[0].data.to;
        this.runplan.to = this.$moment(
          this.runplan.date + " " + this.runplan.from
        )
          .add(this.runplan.duration, "minutes")
          .format("HH:mm");
        this.rowsData.splice(
          this.selectedRows.slice(-1)[0].childIndex + 1,
          0,
          JSON.parse(JSON.stringify(this.runplan))
        );
      } else {
        if (this.rowsData.length > 0) {
          this.runplan.date = this.rowsData.slice(-1)[0].date;
          this.runplan.from = this.rowsData.slice(-1)[0].to;
          this.runplan.to = this.$moment(
            this.runplan.date + " " + this.runplan.from
          )
            .add(this.runplan.duration, "minutes")
            .format("HH:mm");
        }
        this.rowsData.push(JSON.parse(JSON.stringify(this.runplan)));
      }
      this.gridApi.refreshCells();
      setTimeout(
        function () {
          this.saveRP();
        }.bind(this),
        100
      );
      this.contentDirty = true;
    },
    refreshingRunplan() {
      this.fetchingRunplan(this.eventId);
    },
    getRowData() {
      const rows = [];
      this.gridApi.forEachNode(function (node) {
        rows.push(node.data);
      });
      return rows;
    },
    checkAutomatics(val) {
      switch (val.colDef.field) {
        case "duration":
          this.rowsData[val.rowIndex].to = this.$moment(
            this.rowsData[val.rowIndex].date +
              " " +
              this.rowsData[val.rowIndex].from
          )
            .add(val.newValue, "minutes")
            .format("HH:mm");
          break;
        case "from":
          this.rowsData[val.rowIndex].to = this.$moment(
            this.rowsData[val.rowIndex].date +
              " " +
              this.rowsData[val.rowIndex].from
          )
            .add(this.rowsData[val.rowIndex].duration, "minutes")
            .format("HH:mm");
          break;
        case "to":
          this.rowsData[val.rowIndex].duration = this.$moment(
            this.rowsData[val.rowIndex].date +
              " " +
              this.rowsData[val.rowIndex].to
          ).diff(
            this.$moment(
              this.rowsData[val.rowIndex].date +
                " " +
                this.rowsData[val.rowIndex].from
            ),
            "minutes"
          );
          break;
        case "responsible":
          break;
        case "location":
          break;
      }
      this.gridApi.refreshCells();
    },
    createGroups() {
      this.groups.push({ text: "Ei ryhmässä", value: 0, selected: true });
      for (let i = 1; i < 20; i++) {
        this.groups.push({
          text: i,
          value: i,
          selected: false,
        });
      }
    },
    createLocations(val) {
      val.forEach((element) => {
        this.locations.push({
          text: element.name + " - " + element.detailedLocation,
          value: element.id,
        });
      });
    },
    createContacts(val) {
      val.forEach((element) => {
        this.contacts.push({
          text: element.firstname + " " + element.lastname,
          value: element.id,
        });
      });
    },
    refreshRunplan() {
      this.fetchingRunplan(this.eventId);
    },
    addMissingColumns() {
      if (
        this.columnsData.find((element) => element.field === "group") ===
        undefined
      ) {
        this.columnsData.push({
          width: 120,
          field: "group",
          headerName: "Ryhmä",
          sortable: true,
          filter: true,
          resizable: true,
          lockVisible: true,
          editable: true,
          cellEditor: "AgSelect",
          cellEditorPopup: true,
          wrapText: true,
          autoHeight: true,
          cellEditorParams: {
            selectOptions: [
              {
                text: "Ei ryhmässä",
                value: 0,
                selected: false,
                index: 0,
              },
              {
                text: 1,
                value: 1,
                selected: true,
                index: 1,
              },
              {
                text: 2,
                value: 2,
                selected: false,
                index: 2,
              },
              {
                text: 3,
                value: 3,
                selected: false,
                index: 3,
              },
              {
                text: 4,
                value: 4,
                selected: false,
                index: 4,
              },
              {
                text: 5,
                value: 5,
                selected: false,
                index: 5,
              },
              {
                text: 6,
                value: 6,
                selected: false,
                index: 6,
              },
              {
                text: 7,
                value: 7,
                selected: false,
                index: 7,
              },
              {
                text: 8,
                value: 8,
                selected: false,
                index: 8,
              },
              {
                text: 9,
                value: 9,
                selected: false,
                index: 9,
              },
              {
                text: 10,
                value: 10,
                selected: false,
                index: 10,
              },
              {
                text: 11,
                value: 11,
                selected: false,
                index: 11,
              },
              {
                text: 12,
                value: 12,
                selected: false,
                index: 12,
              },
              {
                text: 13,
                value: 13,
                selected: false,
                index: 13,
              },
              {
                text: 14,
                value: 14,
                selected: false,
                index: 14,
              },
              {
                text: 15,
                value: 15,
                selected: false,
                index: 15,
              },
              {
                text: 16,
                value: 16,
                selected: false,
                index: 16,
              },
              {
                text: 17,
                value: 17,
                selected: false,
                index: 17,
              },
              {
                text: 18,
                value: 18,
                selected: false,
                index: 18,
              },
              {
                text: 19,
                value: 19,
                selected: false,
                index: 19,
              },
            ],
            selectPlaceHolder: "Valitse",
            selectOption: "group",
          },
          colId: "group",
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          aggFunc: null,
          pinned: null,
          sort: null,
          sortIndex: null,
          singleClickEdit: false,
        });
      }
    },
    async fetchingRunplan(eventId) {
      this.runplanSchema = await runplanClient.getRunplan(eventId);
      if (this.runplanSchema) {
        if (this.runplanSchema.columnsData)
          this.columnsData = JSON.parse(this.runplanSchema.columnsData);
        if (this.runplanSchema.rowsData)
          this.rowsData = JSON.parse(this.runplanSchema.rowsData);
        //this.notifySuccess(this.$t("runplan.load_success"));
        this.addMissingColumns();
        this.gridApi.refreshCells();
      } else {
        this.runplanSchema = { id: "" };
      }
    },
    saveRP() {
      console.log("AUTOSAVE");
      var temp = this.gridApi.getColumnDefs();
      temp.forEach((element) => {
        element.singleClickEdit = false;
        element.lockVisible = true;
        if (
          element.headerName === "Alkaa" ||
          element.headerName === "Päättyy"
        ) {
          element.editable = false;
          delete element.cellEditor;
          delete element.cellEditorParams;
          element.cellRenderer = "AgInput";
          element.cellRendererParams = {
            inputType: "time",
          };
        }
        if (element.headerName === "Paikka") {
          element.cellEditorParams.selectOptions = [];
        }
        if (element.headerName === "vastuu") {
          element.cellEditorParams.selectOptions = [];
        }
      });
      this.gridApi.setColumnDefs(temp);
      this.savingRunplan();
    },
    async savingRunplan() {
      this.rowsData = this.getRowData();
      this.runplanSchema.rowsData = JSON.stringify(this.rowsData);
      this.runplanSchema.columnsData = JSON.stringify(
        this.gridApi.getColumnDefs()
      );
      if (this.runplanSchema.id !== "1") {
        if (
          await runplanClient.updateRunplan(this.eventId, this.runplanSchema)
        ) {
          this.contentDirty = false;
        }
      } else {
        var payload;
        if (
          (payload = await runplanClient.saveRunplan(
            this.eventId,
            this.runplanSchema
          ))
        ) {
          this.runplanSchema.id = payload.id;
          //this.notifySuccess(this.$t("runplan.create_success"));
          this.contentDirty = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #eeeeeeff,
    )
  );
}

.ag-cell-wrap-text {
  line-height: 150% !important;
}

.ag-body-viewport.ag-layout-normal {
  margin-bottom: 50px !important;
}
</style>
