<template>
  <mdb-container class="grey darken-2">
    <mdb-row>
      <img style="height: 70px" src="@/assets/img/booster_vaaka.png" />
    </mdb-row>
    <mdb-row class="black">
      <h2
        class="white-text"
        @click="handleClick"
        style="cursor: pointer"
        v-if="$store.getters.isAuthenticated"
      >
        {{ $t("home.create_new_event") }}
      </h2>
      <h2 v-if="!$store.getters.isAuthenticated">&nbsp;</h2>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow } from "mdbvue";
export default {
  name: "Event",
  components: {
    mdbContainer,
    mdbRow,
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$router.push({
        name: "EditEvent",
      });
    },
  },
};
</script>

<style></style>
