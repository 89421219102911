<template>
  <mdb-select
    search
    @getValue="getSelectValue"
    v-model="labelOptions"
    :visibleOptions="6"
    :label="label"
    :placeholder="placeholder"
  />
</template>

<script>
import { mdbSelect } from "mdbvue";

export default {
  name: "SelectSingle",
  props: {
    options: Array,
    label: String,
    placeholder: String,
    text: String,
    value: String,
    selectedValue: String,
  },
  components: {
    mdbSelect,
  },
  data: () => ({
    labelOptions: [],
  }),
  methods: {
    setOptions() {
      if (this.options) {
        this.options.forEach(function (option) {
          this.labelOptions.push({
            text: option[this.text],
            value: option[this.value],
            selected: option[this.value] == this.selectedValue,
          });
        }, this);
      }
    },
    getSelectValue(val) {
      var option = this.options.find(({ id }) => id === val);
      this.$emit("selection", option);
    },
  },
  computed: {},
  created: function () {
    this.setOptions();
  },
};
</script>

<style></style>
