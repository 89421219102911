<template>
  <mdb-row
    class="grey lighten-5 m-2 p-2 w-100"
    @click.native="openEvent(event.id)"
    style="cursor: pointer"
  >
    <mdb-col col="2">
      <mdb-row center>
        <img :src="users_image" class="" height="60px" width="60px" />
      </mdb-row>
      <mdb-row center>
        {{ event.logicalId }}
      </mdb-row>
      <mdb-row>
        {{ event.name }}
      </mdb-row>
    </mdb-col>
    <mdb-col col="9">
      <mdb-row class="p-2">
        <mdb-col style="font-weight: bold">{{ $t("event.dates") }}</mdb-col>
        <mdb-col style="font-weight: bold">{{
          $t("event.location.location")
        }}</mdb-col>
        <mdb-col style="font-weight: bold">{{
          $t("event.numOfGuests")
        }}</mdb-col>
        <mdb-col style="font-weight: bold">{{ $t("event.producer") }}</mdb-col>
        <mdb-col style="font-weight: bold">{{
          $t("event.status_label")
        }}</mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col class="bg-light">
          {{ formatDateTimeStr(event.startDateTime, event.endDateTime) }}
        </mdb-col>
        <mdb-col class="bg-light"> {{ locationStr }} </mdb-col>
        <mdb-col class="bg-light">
          {{ event.numOfGuests }}
        </mdb-col>
        <mdb-col class="bg-light"> {{ producerStr }} </mdb-col>
        <mdb-col class="bg-light">
          {{ this.status }}
        </mdb-col>
      </mdb-row>
    </mdb-col>
    <mdb-col col="1" class="m-0 p-0 text-left">
      <mdb-btn outline="elegant" size="sm" @click.native="deleteEvent"
        >poista</mdb-btn
      >
    </mdb-col>
  </mdb-row>
</template>

<script>
import users_image from "@/assets/img/users.svg";
import { mdbRow, mdbCol, mdbBtn } from "mdbvue";
import * as eventClient from "@/client/EventClient.js";
export default {
  name: "Event",
  props: {
    event: Object,
  },
  components: {
    mdbRow,
    mdbCol,
    mdbBtn,
  },
  mounted() {
    this.fetchEventLocations();
    this.fetchEventUsers();
    this.$t("event.statuslist").forEach((element) => {
      if (element.value == this.event.status) this.status = element.text;
    });
  },
  methods: {
    formatDateTimeStr(startDateTime, endDateTime) {
      var start = ""; //this.$t("event.time_not_given");
      var end = ""; //this.$t("event.time_not_given");
      if (startDateTime) start = this.$moment(startDateTime).format("L HH:mm");

      if (endDateTime) end = this.$moment(endDateTime).format("L HH:MM");

      if (start.substr(0, 10) == end.substr(0, 10)) {
        return start + " - " + end.substr(11);
      } else if (end) {
        return start + " - " + end;
      } else {
        return start;
      }
    },
    async fetchEventLocations() {
      var locations;
      if ((locations = await eventClient.getEventLocations(this.event.id))) {
        if (locations.length === 1) {
          this.locationStr = locations[0].name;
        } else if (locations.length === 0) {
          this.locationStr = this.$t("event.location.no_locations");
        } else {
          this.locationStr = this.$t("event.location.multi_location");
        }
      }
    },
    async fetchEventUsers() {
      var users;
      if ((users = await eventClient.getEventUsers(this.event.id))) {
        var prodcr = users.find((user) => user.role == "PRODUCER");
        if (prodcr) {
          this.producerStr = prodcr.firstname + " " + prodcr.lastname;
        } else {
          this.producerStr = this.$t("event.producer_not_set");
        }
      }
    },
    openEvent(id) {
      this.$router.push({
        name: "EditEvent",
        params: { eventId: id },
      });
    },
    deleteEvent(e) {
      e.stopPropagation(); // stops the parent row click event
      this.$emit("onDelete", this.event.id);
    },
  },
  data: () => ({
    users_image,
    locationStr: "",
    producerStr: "",
    status: "",
  }),
};
</script>

<style></style>
