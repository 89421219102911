export default class Contact {
  constructor(
    id = "",
    firstname = "",
    lastname = "",
    title = "",
    email = "",
    phonenumber = "",
    notes = "",
    address = "",
    streetAddress = "",
    postalcode = "",
    city = "",
    categoryId = "",
    roleId = "",
    companyId = ""
  ) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.title = title;
    this.email = email;
    this.phonenumber = phonenumber;
    this.notes = notes;
    this.address = address;
    this.streetAddress = streetAddress;
    this.postalcode = postalcode;
    this.city = city;
    this.categoryId = categoryId;
    this.roleId = roleId;
    this.companyId = companyId;
  }
}
