<template>
  <mdb-container class="m-5">
    <mdb-row tag="h4">
      <div @click="showContacts = !showContacts" style="cursor: pointer">
        {{
          $t("contact.contacts") +
          " " +
          (showContacts ? $t("contact.hide") : $t("contact.show"))
        }}
      </div>
    </mdb-row>
    <div v-if="showContacts">
      <mdb-row>
        <mdb-col>
          <mdb-btn
            class="float-right"
            size="sm"
            outline="elegant"
            @click="showContactSelector = true"
            >{{ $t("event.add_new_contact") }}</mdb-btn
          >
        </mdb-col>
      </mdb-row>
      <mdb-row
        v-for="(eventContact, index) in eventContacts"
        v-bind:index="index"
        v-bind:key="index"
      >
        <mdb-container class="mb-2 border border-dark rounded-top">
          <EventContact
            :eventContact="eventContact"
            :eventId="eventId"
            :userCategories="userCategories"
            :userRoles="userRoles"
            @onDelete="onDelete"
          />
        </mdb-container>
      </mdb-row>
      <mdb-modal
        :show="showContactSelector"
        @close="showContactSelector = false"
        size="lg"
      >
        <ContactSelector
          v-on:closed="showContactSelector = false"
          v-on:contactsSelected="addContacts"
        />
      </mdb-modal>
    </div>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbBtn, mdbCol, mdbModal } from "mdbvue";
import EventContact from "@/components/events/EventContact.vue";
import ContactSelector from "@/components/customers/ContactSelector.vue";
import * as userClient from "@/client/UserClient.js";
import * as eventClient from "@/client/EventClient.js";
export default {
  name: "EventContacts",
  props: {
    eventId: null,
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbBtn,
    mdbCol,
    mdbModal,
    EventContact,
    ContactSelector,
  },
  mounted() {
    this.fetchEventContacts();
    this.fetchUserCategories();
    this.fetchUserRoles();
  },
  methods: {
    async fetchEventContacts() {
      if (
        (this.eventContacts = await eventClient.getEventContacts(this.eventId))
      ) {
        // store contact for runplan usage
        const contacts = [];
        this.eventContacts.forEach((contact) => {
          contacts.push({
            text: contact.firstname + " " + contact.lastname,
            value: contact.id,
          });
        });
        this.$store.commit("addRunplanResponsibles", contacts);
      }
    },
    async addContacts(contacts) {
      contacts.forEach(async function (contact) {
        const e = {
          contactId: contact.id,
          firstname: contact.firstname,
          lastname: contact.lastname,
          eventId: this.eventId,
          categoryId: null,
          roleId: null,
        };
        var payload;
        if ((payload = await eventClient.addEventContact(this.eventId, e))) {
          e.id = payload.id;
          this.eventContacts.push(e);
        }
      }, this);
      this.showContactSelector = false;
    },
    onDelete(eventcontactid) {
      this.$confirmablePopup.show({
        text: this.$t("event.contact.confirm_delete"),
        onConfirm: () => {
          return this.deleteContact(eventcontactid);
        },
      });
    },
    deleteContact(eventcontactid) {
      this.eventContacts = this.eventContacts.filter((eventcontact) => {
        return eventcontact.id != eventcontactid;
      });
    },
    async fetchUserCategories() {
      this.userCategories = await userClient.getUserCategories();
    },
    async fetchUserRoles() {
      this.userRoles = await userClient.getUserRoles();
    },
  },
  data: () => ({
    eventContacts: [],
    allContacts: [],
    showContactSelector: false,
    userCategories: null,
    userRoles: null,
    showContacts: false,
  }),
};
</script>

<style></style>
