<template>
  <mdb-container class="m-5">
    <mdb-row tag="h4">
      <div @click="showUsers = !showUsers" style="cursor: pointer">
        {{
          $t("user.users") +
          " " +
          (showUsers ? $t("user.hide") : $t("user.show"))
        }}
      </div>
    </mdb-row>
    <div v-if="showUsers">
      <mdb-row>
        <mdb-col>
          <mdb-btn
            class="float-right"
            size="sm"
            outline="elegant"
            @click="showSelector = true"
            >{{ $t("event.add_new_user") }}</mdb-btn
          >
        </mdb-col>
      </mdb-row>
      <mdb-row
        v-for="(user, index) in eventUsers"
        v-bind:index="index"
        v-bind:key="index"
      >
        <mdb-container class="mb-2 border border-dark shadow">
          <EventUser :user="user" :eventId="eventId" @onDelete="onDelete" />
        </mdb-container>
      </mdb-row>
      <mdb-modal :show="showSelector" @close="showSelector = false" size="lg">
        <UserSelector
          v-on:closed="showSelector = false"
          v-on:usersSelected="addUsers"
        />
      </mdb-modal>
    </div>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbBtn, mdbCol, mdbModal } from "mdbvue";
import EventUser from "@/components/events/EventUser.vue";
import UserSelector from "@/components/users/UserSelector.vue";
import * as eventClient from "@/client/EventClient.js";
export default {
  name: "EventUsers",
  props: {
    eventId: null,
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbBtn,
    mdbCol,
    mdbModal,
    EventUser,
    UserSelector,
  },
  mounted() {
    this.fetchEventUsers();
  },
  methods: {
    async fetchEventUsers() {
      if ((this.eventUsers = await eventClient.getEventUsers(this.eventId))) {
        // store contact for runplan usage
        const users = [];
        this.eventUsers.forEach((user) => {
          users.push({
            text: user.firstname + " " + user.lastname,
            value: user.id,
          });
        });
        this.$store.commit("addRunplanUsers", users);
      }
    },
    async addUsers(users) {
      const eventusers = [];
      users.forEach((user) => {
        eventusers.push({
          id: user.id,
          secondaryBackendObjectId: user.secondaryBackendObjectId,
          firstname: user.firstname,
          lastname: user.lastname,
          phonenumber: user.phonenumber,
          role: null,
        });
      });
      if (await eventClient.addEventUsers(this.eventId, eventusers)) {
        eventusers.forEach((eventuser) => {
          this.eventUsers.push(eventuser);
        });
      }
      this.showSelector = false;
    },
    onDelete(eventuserid) {
      if (this.eventUsers.length > 1) {
        this.$confirmablePopup.show({
          text: this.$t("event.user.confirm_delete"),
          onConfirm: () => {
            return this.userDeleted(eventuserid);
          },
        });
      } else if (this.eventUsers.length == 1) {
        this.$infoPopup.show({
          text: this.$t("event.user.info_cant_delete_last_user"),
        });
      }
    },
    userDeleted(eventuserid) {
      if (eventClient.deleteEventUser(this.eventId, eventuserid)) {
        this.eventUsers = this.eventUsers.filter((user) => {
          return user.id != eventuserid;
        });
      }
    },
  },
  data: () => ({
    eventUsers: [],
    allUsers: [],
    showSelector: false,
    showUsers: false,
  }),
};
</script>

<style></style>
