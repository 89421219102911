const BASEURL = "https://events.tapahtumabooster.fi/api/v1/";
const BASEURLD = "https://events.tapahtumabooster.fi/api/v1/"; // "http://localhost:5000/api/v1/";

import store from "@/store/index.js";
import Vue from "vue";
import * as msClient from "@/client/MSClient.js";
import i18n from "@/lang/lang.js";

function getHost() {
  //return BASEURL;
  if (window.location.hostname.includes("localhost")) return BASEURLD;
  else return BASEURL;
}

export async function postApi(request) {
  return (
    store.getters.isAuthenticated &&
    Vue.axios
      .post(getHost() + request.url, request.body, {
        headers: {
          Authorization: "Bearer " + store.getters.getUser.accessToken,
        },
      })
      .then((res) => handleSuccess(res))
      .catch((err) => handleError(err, request, "post"))
  );
}
export async function putApi(request) {
  return (
    store.getters.isAuthenticated &&
    Vue.axios
      .put(getHost() + request.url, request.body, {
        headers: {
          Authorization: "Bearer " + store.getters.getUser.accessToken,
        },
      })
      .then((res) => handleSuccess(res))
      .catch((err) => handleError(err, request, "put"))
  );
}
export async function deleteApi(request) {
  return (
    store.getters.isAuthenticated &&
    Vue.axios
      .delete(getHost() + request.url, {
        headers: {
          Authorization: "Bearer " + store.getters.getUser.accessToken,
        },
      })
      .then((res) => handleSuccess(res))
      .catch((err) => handleError(err, request, "delete"))
  );
}

export async function getApi(request) {
  return (
    store.getters.isAuthenticated &&
    Vue.axios
      .get(getHost() + request.url, {
        headers: {
          Authorization: "Bearer " + store.getters.getUser.accessToken,
        },
      })
      .then((res) => handleSuccess(res))
      .catch((err) => handleError(err, request, "get"))
  );
}

function handleSuccess(response) {
  //backend returns always an payload if something usefull is coming
  if (response && response.data && response.data.payload) {
    return response.data.payload;
  } else {
    // otherwise request is handled successfully
    return true;
  }
}

async function handleError(error, request, method) {
  //console.log("RESPONSE STATUS WITH ERROR: " + JSON.stringify(error));
  if (error.message.includes("401")) {
    // TODO: tämä on huono, miksi response.status = undefined kun tulee error response!?
    notifySuccess("resigning to ms...");
    if (await msClient.signInMsUser()) {
      console.log("REDO THE REQUEST: " + JSON.stringify(request));
      if (method == "get") return getApi(request);
      if (method == "post") return postApi(request);
      if (method == "put") return putApi(request);
      if (method == "delete") return deleteApi(request);
    }
  } else if (error.message.includes("403")) {
    // TODO: tämä on huono, miksi response.status = undefined kun tulee error response!?
    showInfo(i18n.t("common.access_denied"));
  } else {
    if (request.errorMsg) {
      // show notif only if message given
      notifyError(request.errorMsg);
    }
  }
  return false;
}

function notifyError(message) {
  Vue.prototype.$notify.error({
    message,
    position: "top right",
    timeOut: 1000,
  });
}

function showInfo(text) {
  Vue.prototype.$infoPopup.show({
    text,
  });
}

function notifySuccess(message) {
  if (message && message != "") {
    Vue.prototype.$notify.success({
      message: message,
      position: "top right",
      timeOut: 1000,
    });
  }
}
